import {cloneDeep} from 'lodash-es';
import {Component, OnInit} from '@angular/core';
import {App} from 'src/app/app';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {UnoTabComponent} from 'src/app/components/uno/uno-tab/uno-tab.component';
import {APAsset} from 'src/app/models/asset-portfolio/asset';
import {DL50Inspection} from 'src/app/models/dl50/dl50-inspection';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {generateUUID} from 'three/src/math/MathUtils';
import {AssetService} from 'src/app/modules/asset-portfolio/services/asset.service';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {UnoTabSectionComponent} from 'src/app/components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {TranslateModule} from '@ngx-translate/core';
import {UnoButtonComponent} from 'src/app/components/uno/uno-button/uno-button.component';
import {UnoTitleComponent} from 'src/app/components/uno/uno-title/uno-title.component';
import {UnoFormModule} from 'src/app/components/uno-forms/uno-form.module';
import {AssetBaseLayout, AssetModelLayout} from 'src/app/modules/asset-portfolio/screens/asset/asset-layout';
import {ServiceList} from 'src/app/http/service-list';
import {Service} from 'src/app/http/service';
import {DL50InspectionStatus, DL50InspectionStatusLabel, DL50InspectionStatusType} from 'src/app/models/dl50/dl50-inspection-status';
import {UnoFormField} from 'src/app/components/uno-forms/uno-form/uno-form-field';
import {CommonModule} from '@angular/common';
import {DL50InspectionQuestionResponse} from 'src/app/models/dl50/dl50-inspection-question-response';
import {UnoFormUtils} from 'src/app/components/uno-forms/uno-form/uno-form-utils';
import {CompanyService} from 'src/app/modules/companies/services/companies.service';
import {UnoNoDataComponent} from 'src/app/components/uno/uno-no-data/uno-no-data.component';
import {UnoListComponent} from 'src/app/components/uno/uno-list/uno-list-component';
import {UnoListItemComponent} from 'src/app/components/uno/uno-list-item/uno-list-item.component';
import {UnoListItemLabelComponent} from 'src/app/components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListLazyLoadHandler} from 'src/app/components/uno/uno-list/uno-list-lazy-load-handler';
import {Loading} from 'src/app/loading';
import {FileUtils} from 'src/app/utils/file-utils';
import {UnoFormModalButton} from 'src/app/components/uno-forms/uno-form-modal/uno-form-modal.component';
import {Company} from 'src/app/models/companies/company';
import {CompareUtils} from 'src/app/utils/compare-utils';
import {DL50InspectionQuestionResponseGap} from 'src/app/models/dl50/dl50-inspection-question-response-gap';
import {UnoFormComponent} from '../../../../../components/uno-forms/uno-form/uno-form.component';
import {DL50Question} from '../../../../../models/dl50/masterdata/dl50-question';
import {DL50InspectionLayouts} from '../dl50-inspections-layouts';
import {Dl50InspectionUtils} from '../../../data/dl50-inspection-utils';
import {Dl50MasterSettingsService} from '../../../services/dl50-master-settings.service';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {DL50InspectionReport} from '../../../data/dl50-inspection-report';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';
import {DL50InspectionService} from '../../../services/dl50-inspection.service';
import {DL50QuestionsService} from '../../../services/dl50-questions.service';

/**
 * Page used to edit dl50 inspections.
 */
@Component({
	selector: 'dl50-inspections-edit-page',
	templateUrl: 'dl50-inspections-edit.page.html',
	standalone: true,
	imports: [
		CommonModule,
		UnoTabComponent,
		UnoTabSectionComponent,
		UnoButtonComponent,
		TranslateModule,
		UnoTitleComponent,
		UnoFormModule,
		FormatDatePipe,
		UnoNoDataComponent,
		UnoListComponent,
		UnoListItemComponent,
		UnoListItemLabelComponent,
		PermissionsPipe
	]
})
export class DL50InspectionsEditPage extends ScreenComponent implements OnInit {
	/**
	 * The permissions to access this screen.
	 */
	public permissions = [UserPermissions.DL50_INSPECTIONS_EDIT, UserPermissions.DL50_INSPECTOR, UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_CLIENT];

	public get session(): any {return Session;}

	public get userPermissions(): any {return UserPermissions;}

	public get inspectionStatus(): any {return DL50InspectionStatus;}

	public assetBaseLayout: any = AssetBaseLayout;

	public assetModelLayout: any = AssetModelLayout;

	public get inspectionLayout(): any { return DL50InspectionLayouts; }

	public get dL50InspectionStatusLabel(): any { return DL50InspectionStatusLabel; }

	/**
	 * Handler for the mobile list
	 */
	public handler: UnoListLazyLoadHandler<any> = new UnoListLazyLoadHandler<any>();

	/**
	 * Flag set true when any asset information is edited.
	 */
	public assetEdited: boolean = false;

	/**
	 * Sets asset edited flag true.
	 */
	public setAssetEdited: Function = () => {
		// Trigger change detection
		this.asset = APAsset.parse(this.asset);
		this.assetEdited = true;
	};

	/**
	 * Reloads asset data if it has changed.
	 */
	public baseInfoEdited: Function = async() => {
		if (this.asset?.uuid !== this.inspection.assetUuid) {
			this.asset = this.inspection.assetUuid ? await AssetService.get(this.inspection.assetUuid) : null;
		}
	};

	/**
	 * Create mode flag. If true, the inspection is being created and still does not exist on the server.
	 */
	public createMode: boolean = true;

	/**
	 * Inspection being edited on this screen. Data is fetched from the API if on edit mode.
	 */
	public inspection: DL50Inspection = null;

	/**
	 * The questions set on master data.
	 */
	public questions: DL50Question[] = [];

	/**
	 * The form layout built from the questions set on master data.
	 */
	public questionsLayout: UnoFormField[] = [];

	/**
	 * A backup list of all the archived gaps organized by the question UUID to be reset on the filtered object of question responses.
	 */
	public archivedGaps: Map<UUID, DL50InspectionQuestionResponseGap[]> = new Map<UUID, DL50InspectionQuestionResponseGap[]>();

	/**
	 * The inspection responses to be edited in the GUI (only with the unarchived gaps).
	 */
	public questionResponses: {[key: UUID]: DL50InspectionQuestionResponse} = {};

	/**
	 * The asset this inspection refers to.
	 */
	public asset: APAsset = null;

	/*
	 * Flag indicating if inspection data is editable.
	 */
	public editable: boolean = false;

	/*
	 * The list of history entries;
	 */
	public history: any[] = [];

	/**
	 * The inspection base info layout (with customizations).
	 */
	public baseInfoLayout: UnoFormField[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		const data = App.navigator.getData();
		if (!data?.createMode && !data?.uuid) {
			App.navigator.pop();
			throw new Error('Missing required data for the screen. Params "createMode" or "uuid" on edit mode are required.');
		}

		this.createMode = data.createMode === true;
		this.assetEdited = false;

		this.baseInfoLayout = cloneDeep(DL50InspectionLayouts.baseInfo);
		const assetField = UnoFormUtils.getFormFieldByAttribute(this.baseInfoLayout, 'assetUuid');
		assetField.onChange = async() => {
			if (this.inspection.assetUuid) {
				this.asset = await AssetService.get(this.inspection.assetUuid);
			}
		};

		App.navigator.setTitle(this.createMode ? 'create' : 'edit');

		// Load questions from master data
		this.questions = (await DL50QuestionsService.list()).questions;

		if (this.createMode) {
			// Get DL50 settings from master data
			const dl50Settings = await Dl50MasterSettingsService.get();
			
			let dl50DefaultCompany: Company;
			if (dl50Settings.companyUuid) {
				dl50DefaultCompany = await CompanyService.get(dl50Settings.companyUuid);
			}
			
			this.inspection = new DL50Inspection();
			this.inspection.uuid = generateUUID();
			this.inspection.status = DL50InspectionStatus.IN_PROGRESS;

			if (dl50DefaultCompany) {
				this.inspection.companyUuid = dl50DefaultCompany.uuid;
				this.inspection.equipmentOwner = dl50DefaultCompany.name;
			}

			this.inspection.validationEmissionLocation = 'Maia';

			this.buildInspectionQuestionsData();
		} else {
			await this.loadData(data.uuid);
		}

		this.editable = Session.hasPermissions([UserPermissions.DL50_INSPECTIONS_CREATE, UserPermissions.DL50_INSPECTIONS_EDIT]) && this.inspection.status !== this.inspectionStatus.FINISHED;

		this.handler.loadMore = async(count: number, pageSize: number) => {
			const request = await Service.fetch(ServiceList.dl50.inspections.history.list, null, null, {uuid: this.inspection.uuid}, Session.session);

			this.history = request.response.history;

			return {
				elements: request.response.history,
				hasMore: request.response.hasMore
			};
		};

		await this.resetHistory();
	}

	/**
	 * Builds the DL50 questions depending objects.
	 */
	public buildInspectionQuestionsData(): void {
		// Build the questions form data object
		this.questionResponses = this.inspection.initialize(this.questions);

		// Build the questions form layout
		this.questionsLayout = Dl50InspectionUtils.buildQuestionsLayout(this.questions, this.inspection);

		// Filter the archived gaps from the original question responses
		for (const questionUuid in this.questionResponses) {
			this.archivedGaps.set(questionUuid, this.questionResponses[questionUuid].gaps.filter((g: DL50InspectionQuestionResponseGap) => { return g.archived; }));

			// Exclude the archived gaps
			this.questionResponses[questionUuid].gaps = this.questionResponses[questionUuid].gaps.filter((g: DL50InspectionQuestionResponseGap) => { return !g.archived; });
		}
	}

	/**
	 * Resets all the items related to the history
	 */
	public async resetHistory(): Promise<void> {
		this.history = [];
		await this.handler.reset();
	}

	/**
	 * Loads all the data needed to present on edit screen
	 *
	 * @param uuid - The UUID of the inspection to load the data from.
	 */
	public async loadData(uuid: UUID): Promise<void> {
		this.inspection = await DL50InspectionService.get(uuid);

		// If there are no emission data selected, set the current date
		if (this.inspection.status === DL50InspectionStatus.VALIDATION && !this.inspection.validationEmissionDate) {
			this.inspection.validationEmissionDate = new Date();
		}

		// Load inspection asset data
		if (this.inspection.assetUuid) {
			this.asset = await AssetService.get(this.inspection.assetUuid);
		}

		this.buildInspectionQuestionsData();
	}

	/**
	 * Check if all the required forms of the inspection (the visible ones) are filled. Automatically displays a GUI alert message on error.
	 *
	 * @param status - The status of the inspection to validate the forms accordingly.
	 */
	public checkInspectionRequiredForms(status: DL50InspectionStatusType): boolean {
		// Check required fields of asset content
		if (
			!UnoFormComponent.requiredFilled(DL50InspectionLayouts.baseInfo, this.inspection) ||
			!UnoFormComponent.requiredFilled(DL50InspectionLayouts.generalData, this.inspection) ||
			this.inspection.hasLoadTests && !UnoFormComponent.requiredFilled(DL50InspectionLayouts.loadTestChecklist, this.inspection) ||
			this.inspection.hasElectricalTests && !UnoFormComponent.requiredFilled(DL50InspectionLayouts.electricalTestChecklist, this.inspection) ||
			!UnoFormComponent.requiredFilled(this.questionsLayout, this.questionResponses) ||
			!UnoFormComponent.requiredFilled(DL50InspectionLayouts.inspectionFinalNotes, this.inspection) ||
			status > DL50InspectionStatus.IN_PROGRESS && (
				!UnoFormComponent.requiredFilled(DL50InspectionLayouts.inspectionValidation, this.inspection) ||
				!UnoFormComponent.requiredFilled(DL50InspectionLayouts.inspectionSupervisionRejected, this.inspection) ||
				!UnoFormComponent.requiredFilled(DL50InspectionLayouts.inspectionClientFeedback, this.inspection)
			)
		) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return false;
		}

		return true;
	}

	/**
	 * Check if all the required forms of the asset are filled. Automatically displays a GUI alert message on error.
	 *
	 * @returns True if all forms are filled, false otherwise.
	 */
	public checkAssetRequiredForms(): boolean {
		// Check required fields of asset content
		if (!UnoFormComponent.requiredFilled(this.assetBaseLayout, this.asset) || !UnoFormComponent.requiredFilled(this.assetModelLayout, this.asset)) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return false;
		}

		return true;
	}

	/**
	 * Saves a DL50 inspection on API.
	 *
	 * It can create or update an inspection depending on the createMode.
	 *
	 * Checks the required fields only on create mode or on status change. User must be able to save the inspection in any state but forms are only checked when changing inspection status.
	 *
	 * Asset forms are also checked when the asset has been edited.
	 *
	 * @param stayOnPage - Either to stay or leave the page after saving.
	 * @param destinationStatus - The status to update the inspection with.
	 */
	public async save(stayOnPage: boolean = false, destinationStatus?: DL50InspectionStatusType): Promise<void> {
		if (this.createMode && !Session.hasPermissions(UserPermissions.DL50_INSPECTIONS_CREATE) || !this.createMode && !Session.hasPermissions(UserPermissions.DL50_INSPECTIONS_EDIT)) {
			Modal.alert(Locale.get('error'), Locale.get('errorUpdatingInspectionPermission'));
			return;
		}

		// Present modal to edit rejection comments
		if (destinationStatus === DL50InspectionStatus.SUPERVISION_REJECTED) {
			await Modal.form(Locale.get('supervisionRejected'), this.inspection, this.inspectionLayout.inspectionSupervisionRejected);
		}

		const inspection: DL50Inspection = cloneDeep(this.inspection);

		// Set back the archived gaps from the original question response objects on the inspection object to create/update
		for (const i in inspection.responses) {
			inspection.responses[i].gaps.push(...this.archivedGaps.get(inspection.responses[i].questionUuid));
		}

		inspection.clean();

		// Check required fields
		if ((destinationStatus && destinationStatus !== inspection.status || this.createMode ) && !this.checkInspectionRequiredForms(inspection.status) || this.assetEdited && !this.checkAssetRequiredForms()) {
			return;
		}

		// Set new status
		if (destinationStatus && destinationStatus !== inspection.status) {
			inspection.status = destinationStatus;
		}
		
		// Validate questions and tests evaluations
		if (DL50Inspection.inconsistentEvaluations(inspection)) {
			// Check if the user wants to proceed as is
			const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmInspectionEvaluationInconsistency'));
			if (!confirm) {
				return;
			}
		}		

		try {
			// Save the asset data
			if (this.assetEdited) {
				if (!Session.hasPermissions([UserPermissions.ASSET_PORTFOLIO_ASSET_EDIT])) {
					throw new Error('User does not have permission to edit asset.');
				}

				try {
					await Service.fetch(ServiceList.assetPortfolio.asset.update, null, null, this.asset, Session.session);
				} catch {
					Modal.alert(Locale.get('error'), Locale.get('errorUpdatingAsset'));
					return;
				}
			}

			// Update inspection data
			if (this.createMode) {
				await DL50InspectionService.create(inspection);
			} else {
				await DL50InspectionService.update(inspection);
			}

			Modal.toast(Locale.get(this.createMode ? 'createdSuccessfully' : 'updatedSuccessfully'), 3000, 'success');

			if (!stayOnPage) {
				App.navigator.pop();
				return;
			}

			await this.loadData(this.inspection.uuid);
		} catch {
			Modal.alert(Locale.get('error'), Locale.get(this.createMode ? 'errorCreatingInspection' : 'errorUpdatingInspection'));
			return;
		}
	}

	/**
	 * Deletes the inspection from API.
	 */
	public async delete(): Promise<void> {
		if (!Session.hasPermissions(UserPermissions.DL50_INSPECTIONS_DELETE)) {
			throw new Error('User does not have permissions to delete this inspection.');
		}

		// Ask to confirm deletion
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await DL50InspectionService.delete(this.inspection.uuid);
			Modal.toast(Locale.get('success'), 3000, 'success');
			App.navigator.pop();
		}
	}

	/**
	 * Open an history entry in a modal to display the changes performed on a selected entry.
	 *
	 * @param historyId - History id of the entry from history list to be displayed.
	 */
	public async openHistoryEntry(history: any): Promise<void> {
		const buttons: UnoFormModalButton[] = [
			{
				success: false,
				label: 'close',
				color: 'primary'
			}
		];

		if (history.status === DL50InspectionStatus.FINISHED) {
			buttons.push({
				success: false,
				label: 'exportReportDOCX',
				color: 'primary',
				callback: async(): Promise<void> => {
					await this.exportReportDOCX(true, history);
				}
			}, {
				success: false,
				label: 'exportReportPDF',
				color: 'primary',
				callback: async(): Promise<void> => {
					await this.exportReportPDF(history);
				}
			});
		}

		const asset: APAsset = await AssetService.get(history.assetUuid);

		history.pictures = asset.pictures;

		const historyFormLayout: UnoFormField[] = DL50InspectionLayouts.baseInfo.concat(DL50InspectionLayouts.assetInfo, DL50InspectionLayouts.generalData, this.questionsLayout, DL50InspectionLayouts.inspectionFinalNotes);

		await Modal.form(Locale.get('history'), history, historyFormLayout, buttons, false);
	}

	/*
	 * Generate and export a DOCX report for the DL50 inspection.
	 *
	 * If the report revision number is higher than 0, compare all the changes in the report against the previous revision.
	 * 
	 * The previous version is the oldest history change of the previous inspection revision count (because the inspection can be saved many times on the client feedback status with the same revision value, only incremented when changing to the finished status again).
	 *
	 * @param writeToFile - If true, the report is written to a file.
	 * @returns The report generated as array buffer.
	*/
	public async exportReportDOCX(writeToFile: boolean = true, inspection: any = this.inspection): Promise<ArrayBuffer> {
		Loading.show();

		try {
			let asset: APAsset = this.asset;

			// Get the asset if the the export is from the history.
			if (inspection !== this.inspection) {
				asset = await AssetService.get(inspection.assetUuid);
				inspection.uuid = this.inspection.uuid;
				inspection = DL50Inspection.parse(inspection);
			}

			let previousInspectionRevision: DL50Inspection = null;
			if (inspection.revision > 0) {
				// Get previous inspection revision
				previousInspectionRevision = DL50Inspection.parse(this.history
					// Filter the history changes by the previous revision count
					.filter((h: any) => { return h.revision === inspection.revision - 1;})
					// Sort the history entries by the creation date and get the oldest one
					.sort(function(a, b) { return CompareUtils.compareDate(a.createdAt, b.createdAt); })[0]);
			}

			const company: Company = inspection.companyUuid ? await CompanyService.get(inspection.companyUuid) : null;
			const doc: ArrayBuffer = await DL50InspectionReport.generateDocx(inspection, previousInspectionRevision, this.questions, company, asset);
			if (writeToFile) {
				FileUtils.writeFileArrayBuffer('dl50_' + inspection.uuid + '.docx', doc);
			}
			return doc;
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorGeneratingReportDetails', {details: e}));
		} finally {
			Loading.hide();
		}

		return null;
	}

	/**
	 * Generate a PDF report for the DL50 inspection.
	 *
	 * Use file conversion server to convert from DOCX to PDF.
	 * 
	 * @param inspection - The inspection to export.
	 */
	public async exportReportPDF(inspection: any = this.inspection): Promise<void> {
		Loading.show();

		// Get the asset if the the export is from the history.
		if (inspection !== this.inspection) {
			inspection.uuid = this.inspection.uuid;
			inspection = DL50Inspection.parse(inspection);
		}

		const doc: ArrayBuffer = await this.exportReportDOCX(false, inspection);
		if (!doc) {
			Loading.hide();
			return;
		}

		const form = new FormData();
		form.append('file', new Blob([doc]), this.inspection.uuid + '.docx');

		try {
			const convertedFile = (await Service.fetch(ServiceList.fileConverter.docxToPdf, null, null, form, null)).response;
			FileUtils.writeFileArrayBuffer('dl50_' + this.inspection.uuid + '.pdf', convertedFile);
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorConvertingReport', {details: e}));
		}

		Loading.hide();
	}
}
