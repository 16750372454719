import {cloneDeep} from 'lodash-es';
import {Component, ViewChild} from '@angular/core';
import {App} from 'src/app/app';
import {Session} from 'src/app/session';
import {Modal} from 'src/app/modal';
import {CommonModule, NgTemplateOutlet} from '@angular/common';
import {Locale} from 'src/app/locale/locale';
import {AssetBaseLayout} from 'src/app/modules/asset-portfolio/screens/asset/asset-layout';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {AssetService} from 'src/app/modules/asset-portfolio/services/asset.service';
import {DL50Inspection} from 'src/app/models/dl50/dl50-inspection';
import {GAGap} from 'src/app/models/gap-analysis/gaps/gap';
import {Dl50InspectionUtils} from 'src/app/modules/dl50/data/dl50-inspection-utils';
import {GAGapOrigin} from 'src/app/models/gap-analysis/gaps/gap-origin';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormUtils} from 'src/app/components/uno-forms/uno-form/uno-form-utils';
import {ObjectKeysPipe} from 'src/app/pipes/object-keys.pipe';
import {DL50Question} from 'src/app/models/dl50/masterdata/dl50-question';
import {DL50InspectionLayouts} from 'src/app/modules/dl50/screens/inspections/dl50-inspections-layouts';
import {DL50InspectionQuestionResponseGap} from 'src/app/models/dl50/dl50-inspection-question-response-gap';
import {DL50InspectionQuestionResponse} from 'src/app/models/dl50/dl50-inspection-question-response';
import {DL50InspectionService} from 'src/app/modules/dl50/services/dl50-inspection.service';
import {DL50QuestionsService} from 'src/app/modules/dl50/services/dl50-questions.service';
import {Resource} from 'src/app/models/resource';
import {UnoFormField} from '../../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormModule} from '../../../../../components/uno-forms/uno-form.module';
import {UnoFormComponent} from '../../../../../components/uno-forms/uno-form/uno-form.component';
import {ServiceList} from '../../../../../http/service-list';
import {Service} from '../../../../../http/service';
import {APAsset} from '../../../../../models/asset-portfolio/asset';
import {UUID} from '../../../../../models/uuid';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {GAActionPlanStatus} from '../../../../../models/gap-analysis/action-plans/action-plan-status';
import {GAActionPlanHistory} from '../../../../../models/gap-analysis/action-plans/action-plan-history';
import {GAActionPlan} from '../../../../../models/gap-analysis/action-plans/action-plan';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoTitleComponent} from '../../../../../components/uno/uno-title/uno-title.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoTabSectionComponent} from '../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../../components/uno/uno-tab/uno-tab.component';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';
import {GapAnalysisService} from '../../../services/gap-analysis.service';
import {GAActionPlanFormLayout} from '../action-plan-form-layout';

/**
 * An extension of a DL50 inspection containing the inspection extra data to be used on forms.
 */
export class DL50InspectionForm extends DL50Inspection {
	/**
     * The question response objects modifed from the original inspection responses attribute to fit the form layout.
     */
	public questionResponses: {[key: string]: any} = {};
    
	/**
     * The pictures that represent the selected asset for the inspection
     */
	public pictures: Resource[] = [];
}

/**
 * Screen to create, edit and present gap analysis action plans info.
 * 
 * When in creation mode, createMode attribute should be passed as true on navigator data.
 * 
 * When in edition mode, the UUID of the action plan to edit should be passed in order to load its data from DB into the screen forms.
 * 
 * Action plan history entries are also presented on screen.
 */
@Component({
	selector: 'gap-analysis-action-plan-edit-page',
	templateUrl: './action-plan-edit.page.html',
	standalone: true,
	imports: [NgTemplateOutlet, CommonModule, ObjectKeysPipe, UnoTabComponent, UnoTabSectionComponent, UnoFormModule, UnoButtonComponent, UnoTitleComponent, IonicModule, TranslateModule, FormatDatePipe, PermissionsPipe]
})
export class GAActionPlanEditPage extends ScreenComponent {
	@ViewChild('actionPlanForm', {static: false})
	public actionPlanForm: UnoFormComponent = null;

	public app: any = App;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public actionPlanStatus: any = GAActionPlanStatus;
	
	public get assetsLayout(): UnoFormField[] { return AssetBaseLayout; }
	
	public get dl50InspectionLayouts(): any { return DL50InspectionLayouts; }
	
	public permissions = [UserPermissions.GA_ACTION_PLAN_CREATE, UserPermissions.GA_ACTION_PLAN_EDIT];

	/**
	 * The layout to present action plan data. 
	 */
	public actionPlanLayout: UnoFormField[] = [];

	/**
	 * Action plan being edited in this screen.
	 */
	public actionPlan: GAActionPlan = null;

	/**
	 * Flag to indicate if the screen is in create mode.
	 */
	public createMode: boolean = false;

	/**
	 * Edit history of the action plan.
	 */
	public history: GAActionPlanHistory[] = [];

	/**
	 * The gaps objects from the action plan selected gap UUIDs.
	 */
	public gaps: GAGap[] = [];

	/**
	 * The assets referenced on this action plan.
	 */
	public assets: APAsset[] = [];

	/**
	 * The DL50 inspections related to the gaps of this action plan.
	 */
	public dl50Inspections: DL50Inspection[] = [];

	/**
	 * The DL50 questions set on master data for the DL50 inspections.
	 */
	public dl50Questions: DL50Question[] = [];

	/**
	 * The objects that contain the DL50 inspection question response objects and layouts that originated the selected gaps.
	 */
	public dl50InspectionQuestionsLayoutObjs: {object: {[key: string]: any}, layout: UnoFormField[]}[] = [];
	
	/**
	 * The objects containing the limited view inspections data to the selected gaps and the filtered layouts for each inspection.
	 */
	public dl50InspectionsLayoutObjs: {object: DL50InspectionForm, layout: UnoFormField}[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		// Reset all the objects
		this.createMode = false;
		this.actionPlan = null;
		this.history = [];
		this.resetData();

		App.navigator.setTitle('actionPlan');
		
		// Modify action plan layout to reload gaps depending info whenever the selected gaps change
		this.actionPlanLayout = cloneDeep(GAActionPlanFormLayout);
		UnoFormUtils.getFormFieldByAttribute(this.actionPlanLayout, 'gapUuids').onChange = async(object: any, row: UnoFormField, value: any): Promise<void> => {
			await this.loadGaps();
		};

		const data: {createMode: boolean, uuid: UUID, gapUuids: UUID[]} = App.navigator.getData();
		if (!data?.uuid && !data?.createMode) {
			App.navigator.pop();
			return;
		}

		if (data?.createMode) {
			this.createMode = true;
			this.actionPlan = new GAActionPlan();

			if (data.gapUuids) {
				this.actionPlan.gapUuids = data.gapUuids;
			}
		} else {
			await Promise.all([
				await this.loadActionPlan(data.uuid),
				await this.loadHistory(data.uuid)
			]);
		}

		await this.loadGaps();
	}

	/**
	 * Resets all the objects that depend on selected gaps.
	 */
	public resetData(): void {
		this.gaps = [];
		this.assets = [];
		this.dl50Inspections = [];
		this.dl50InspectionQuestionsLayoutObjs = [];
		this.dl50InspectionsLayoutObjs = [];
	}

	/**
	 * Builds DL50 inspections and gap fields layouts and prepare data to be shown on those forms.
	 * 
	 * It build the gap fields and the inspection objects and layouts from the selected gaps.
	 * 
	 * The layouts are mostly non editable. Only question fields (but not the gaps) and the inspection conclusion field (only if question evaluations doesn't fit the conclusion value) can be edited. Not applicable fields are not aqvailable on question evaluation forms.
	 * 
	 * Conclusion is only editable when the action plan is in the reinspection status and the inspection evaluations do not fit the actual conclusion.
	 */
	public buildDL50InspectionsData(): void {
		// Gather all the DL50 response gap UUIDs by question response UUID (dl50InspectionQuestionResponseUuid -> dl50QuestionGapUuid[])
		const questionResponseGapUuids: Map<UUID, UUID[]> = new Map<UUID, UUID[]>();
		for (const g of this.gaps) {
			if (g.origin === GAGapOrigin.DL50_INSPECTIONS) {
				if (!questionResponseGapUuids.has(g.dl50InspectionQuestionResponseUuid)) {
					questionResponseGapUuids.set(g.dl50InspectionQuestionResponseUuid, [g.dl50QuestionGapUuid]);
				} else {
					const responseGaps = questionResponseGapUuids.get(g.dl50InspectionQuestionResponseUuid);
					questionResponseGapUuids.set(g.dl50InspectionQuestionResponseUuid, responseGaps.concat(g.dl50QuestionGapUuid));
				}
			}
		}

		// Build inspection forms data objects and layouts
		for (let i = 0; i < this.dl50Inspections.length; i++) {
			// The manipulated inspection object to be presented on the forms of this screen
			const inspection: DL50InspectionForm = cloneDeep(this.dl50Inspections[i]);

			// Get asset pictures to be shown on inspection
			inspection.pictures = this.assets.find((a: APAsset) => { return a.uuid === inspection.assetUuid; }).pictures;

			// Get inspection question responses
			inspection.questionResponses = inspection.initialize(this.dl50Questions);

			// Filter inspection responses for the selected gaps
			for (const questionUuid in inspection.questionResponses) {
				// Filter inspection responses by the selected gaps (only the responses that have some of the selected gaps)
				if (!questionResponseGapUuids.has(inspection.questionResponses[questionUuid].uuid)) {
					delete inspection.questionResponses[questionUuid];
					continue;
				}

				// Filter all the response gaps by the selected gaps
				const responseGapsUuids: UUID[] = questionResponseGapUuids.get(inspection.questionResponses[questionUuid].uuid);
				inspection.questionResponses[questionUuid].gaps = inspection.questionResponses[questionUuid].gaps.filter((rg: DL50InspectionQuestionResponseGap) => { return responseGapsUuids.includes(rg.gapUuid); });
			}

			// Set editable false for all fields
			const nonEditableBaseInfoLayout: UnoFormField[] = cloneDeep(DL50InspectionLayouts.baseInfo);
			const nonEditableFinalNotesLayout: UnoFormField[] = cloneDeep(DL50InspectionLayouts.inspectionFinalNotes);
			for (const field of [...nonEditableBaseInfoLayout, ...nonEditableFinalNotesLayout]) {
				if (field.attribute === 'finalNotesConclusion') {
					// Inspection final notes conclusion field is enabled for edition if the inspection has inconsistencies on responses
					field.editable = (object, row) => {
						// Find the original inspection of this limited inspection
						const inspec: DL50Inspection = this.dl50Inspections.find((insp: DL50Inspection) => { return insp.uuid === inspection.uuid; });
						// Validate the inconsistencies on the original inspection object
						return DL50Inspection.inconsistentEvaluations(inspec);
					};
					
					field.onChange = (object, row, value) => {
						// Find the original inspection of this limited inspection
						const inspec: DL50Inspection = this.dl50Inspections.find((insp: DL50Inspection) => { return insp.uuid === inspection.uuid; });
						// Update the final notes conclusion value on the original inspection object
						inspec.finalNotesConclusion = value;
					};
				} else {
					field.editable = false;
				}
			}

			// Filter questions layout fields to present only the fields that generated the selected gaps (through the remaining question responses after being filtered by the selected gaps) for this inspection
			const questionsLayout = Dl50InspectionUtils.buildQuestionsLayout(this.dl50Questions, inspection).filter((f: UnoFormField) => {
				return inspection.questionResponses[f.attribute] !== undefined;
			});

			// Hide gaps layout "not applicable" fields and do not allow gap field to be edited. Only the question can be edited.
			for (const questionField of questionsLayout) {
				questionField.editable = true;
				for (const gapField of questionField.fields) {
					// Hide "not applicable" field
					if (gapField.attribute === 'notApplicable') {
						gapField.isActive = false;
					}
					
					// Do not allow to edit the gap field
					if (gapField.attribute === 'gaps') {
						gapField.disableDelete = true;
						gapField.disableSort = true;
						
						for (const gapFieldField of gapField.fields) {
							// Allow the archived field to be edited (only when in the reinspection status)
							gapFieldField.editable = (object, row) => { return row.attribute === 'archived' && this.actionPlan.status === GAActionPlanStatus.REINSPECTION; };
							
							gapFieldField.onChange = (object, row, value) => {
								if (row.attribute === 'archived') {
									// Find the inspection of this field
									const originalInspection: DL50Inspection = this.dl50Inspections.find((insp: DL50Inspection) => { return insp.uuid === inspection.uuid; });

									// Set the modified data on the original inspection (from the question field attribute and the gap field attribute)
									const questionResponse = originalInspection.responses.find((response: DL50InspectionQuestionResponse) => { return response.questionUuid === questionField.attribute; });
									
									// Find the modified question response gap
									const responseGap = questionResponse.gaps.find((rg: DL50InspectionQuestionResponseGap) => { return rg.uuid === object.uuid; });
									responseGap[row.attribute] = value;
								}
							};
						}						
					}

					if (gapField.attribute === 'evaluation' || gapField.attribute === 'photos' || gapField.attribute === 'comments') {
						// Set a callback to change the original inspection on changes
						gapField.onChange = (object, row, value) => {
							// Find the inspection of this field
							const originalInspection: DL50Inspection = this.dl50Inspections.find((insp: DL50Inspection) => { return insp.uuid === object.inspectionUuid; });

							// Set the modified data on the original inspection (from the question field attribute and the gap field attribute)
							const questionResponse = originalInspection.responses.find((response: DL50InspectionQuestionResponse) => { return response.questionUuid === questionField.attribute; });
							// Set the modified attribute value
							questionResponse[row.attribute] = value;
						};
					}
				}
			}
						
			const dl50InspectionGapsSubformField: UnoFormField = {
				required: true,
				editable: (object, row) => { return this.actionPlan.status === GAActionPlanStatus.REINSPECTION; },
				type: UnoFormFieldTypes.SUB_FORM,
				label: Locale.get('inspection') + (inspection.label?.length > 0 ? ' ' + inspection.label : '') + ' (' + inspection.uuid + ')',
				fields: questionsLayout,
				attribute: inspection.uuid
			};

			this.dl50InspectionQuestionsLayoutObjs.push({
				object: {[inspection.uuid]: inspection.questionResponses},
				layout: [dl50InspectionGapsSubformField]
			});

			// Build the DL50 inspection layout and object
			this.dl50InspectionsLayoutObjs.push({
				object: inspection,
				layout: {
					required: true,
					type: UnoFormFieldTypes.SUB_FORM,
					expanded: false,
					label: (object, row) => {
						return Locale.get('inspection') + (object.label?.length > 0 ? ' ' + object.label : '') + ' (' + object.uuid + ')'; 
					},
					fields: [
						...nonEditableBaseInfoLayout,
						{
							required: true,
							type: UnoFormFieldTypes.SUB_FORM,
							fields: questionsLayout,
							attribute: 'questionResponses',
							label: 'safetyRequirements',
							expanded: false
						},
						...nonEditableFinalNotesLayout
					]
				}
			});
		}
	}

	/**
	 * Loads all the data that depend on action plan gaps.
	 */
	public async loadGaps(): Promise<void> {
		this.resetData();

		[this.gaps, this.assets, this.dl50Inspections] = await Promise.all([
			// Load all gaps objects
			GapAnalysisService.gatGapsBatch(this.actionPlan.gapUuids),
			// Get all the assets referenced by the gaps of this action plan
			AssetService.listByGapUuids(this.actionPlan.gapUuids),
			// Get all the DL50 inspections referenced by the gaps of this action plan
			DL50InspectionService.listByGapUuids(this.actionPlan.gapUuids)
		]);

		// Load DL50 questions only if needed and not loaded already
		if (this.dl50Inspections.length > 0) {
			if (this.dl50Questions.length === 0) {
				this.dl50Questions = (await DL50QuestionsService.list()).questions;
			}
			
			this.buildDL50InspectionsData();
		}
	}

	/**
	 * Load the action plan data from API.
	 *
	 * @param uuid - UUID of the action plan.
	 */
	public async loadActionPlan(uuid: UUID): Promise<void> {
		this.actionPlan = GAActionPlan.parse((await Service.fetch(ServiceList.gapAnalysis.actionPlan.get, null, null, {uuid: uuid}, Session.session)).response.actionPlan);
	}

	/**
	 * Load the action plan history from database.
	 * 
	 * @param uuid - UUID of the action plan.
	 */
	public async loadHistory(uuid: UUID): Promise<void> {
		this.history = (await Service.fetch(ServiceList.gapAnalysis.actionPlan.history.list, null, null, {uuid: uuid}, Session.session)).response.actionPlanHistories.map((h) => {return GAActionPlanHistory.parse(h); });
	}

	/**
	 * Save the data edited on the screen.
	 * 
	 * Depending on createMode value, this method can either create or update an action plan.
	 * 
	 * If the aciton plan is in reinspection status, the inspections fields that originated the gaps and the inspection conclusion may be edited also.
	 * 
	 * @param status - The optional status to transit the action plan to.
	 * @param stayOnPage - Whether to stay or leave the page after save.
	 */
	public async save(status?: number, stayOnPage: boolean = false): Promise<void> {
		if (!this.actionPlanForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		const actionPlan = structuredClone(this.actionPlan);
		if (status !== undefined) {
			actionPlan.status = status;
		}

		// Save the inspections only on reinspection status
		if (this.actionPlan.status === GAActionPlanStatus.REINSPECTION) {
			const inconsistentInspectionUuids: UUID[] = [];
			for (const inspection of this.dl50Inspections) {
				// Clean inspection data
				inspection.clean();

				if (DL50Inspection.inconsistentEvaluations(inspection)) {
					inconsistentInspectionUuids.push(inspection.uuid);
				}
			}

			if (inconsistentInspectionUuids.length === 0 || inconsistentInspectionUuids.length > 0 && await Modal.confirm(Locale.get('confirm'), Locale.get('confirmManyInspectionsInconsistentData', {uuids: inconsistentInspectionUuids.map((id: UUID) => { return '"' + id + '"';}).join('\n')}))) {
				try {
					// Create/save the action plan
					await Service.fetch(ServiceList.gapAnalysis.actionPlan.update, null, null, actionPlan, Session.session);
					
					// Save the dl50 inspections
					for (const inspection of this.dl50Inspections) {
						await DL50InspectionService.update(inspection);
					}
					
					Modal.toast(Locale.get('updatedSuccessfully'), 3000, 'success');					
				} catch {
					await Modal.toast(Locale.get('dataUpdateError'), 3000, 'danger');
				}
			} else {
				return;
			}
		} else {
			// Create/save the action plan
			await Service.fetch(this.createMode ? ServiceList.gapAnalysis.actionPlan.create : ServiceList.gapAnalysis.actionPlan.update, null, null, actionPlan, Session.session);
			
			Modal.toast(Locale.get(this.createMode ? 'createdSuccessfully' : 'updatedSuccessfully'), 3000, 'success');
		}

		if (this.createMode || !stayOnPage) {
			App.navigator.pop();
			return;
		}
		
		// Every time an update occurs, there is an history change.
		this.loadHistory(this.actionPlan.uuid);
	}

	/**
	 * Delete the action plan from the API.
	 */
	public async delete(): Promise<void> {
		// Ask for confirmation before delete
		if (await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'))) {
			await Service.fetch(ServiceList.gapAnalysis.actionPlan.delete, null, null, {uuid: this.actionPlan.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'), 3000, 'success');
			App.navigator.pop();
			return;
		}
	}
}
