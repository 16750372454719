import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {App} from 'src/app/app';
import {Locale} from 'src/app/locale/locale';
import {IonicModule} from '@ionic/angular';
import {CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf} from '@angular/cdk/scrolling';
import {TranslateModule} from '@ngx-translate/core';
import {ObjectKeysPipe} from 'src/app/pipes/object-keys.pipe';
import {Settings} from 'src/app/modules/settings/data/settings';
import {NgClass, NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Resource} from '../../../models/resource';
import {UnoTableColumnLayout, UnoTableColumnType, UnoTableComponent} from '../uno-table/uno-table.component';
import {UnoListComponent} from '../uno-list/uno-list-component';
import {UnoListItemComponent} from '../uno-list-item/uno-list-item.component';
import {UnoListItemLabelComponent} from '../uno-list-item/uno-list-item-label.component';
import {UnoListLazyLoadHandler} from '../uno-list/uno-list-lazy-load-handler';
import {UnoNoDataComponent} from '../uno-no-data/uno-no-data.component';
import {UnoListItemIconComponent} from '../uno-list-item/uno-list-item-icon.component';
import {UnoTableExport} from '../uno-table/uno-table-export';
import {UnoIconComponent} from '../uno-icon/uno-icon.component';

/**
 * The different types that are accepted by the table
 */
export enum ListDisplayStyle {
	/**
	 * Display a title.
	 */
	TITLE = 1,

	/**
	 * Display text.
	 */
	TEXT = 2,

	/**
	 * Display a greyed out label.
	 */
	LABEL = 3,
};

/**
 * UNO responsive table list component is a component to display the uno-table-component when the user is in desktop mode and the uno-list when the user is on mobile.
 */
@Component({
	selector: 'uno-responsive-table-list',
	templateUrl: './uno-responsive-table-list.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['uno-responsive-table-list.component.css'],
	standalone: true,
	imports: [
		UnoTableComponent,
		UnoListComponent,
		UnoListItemComponent,
		UnoListItemLabelComponent,
		IonicModule,
		CdkVirtualScrollViewport,
		CdkFixedSizeVirtualScroll,
		CdkVirtualForOf,
		TranslateModule,
		ObjectKeysPipe,
		UnoNoDataComponent,
		UnoListItemIconComponent,
		NgStyle,
		NgClass,
		FormsModule,
		UnoIconComponent
	]
})

export class UnoResponsiveTableListComponent implements OnInit {
	public app: any = App;

	public locale: any = Locale;

	public settings: any = Settings;;

	public resource: any = Resource;;

	public unoTableColumnType: any = UnoTableColumnType;

	public listDisplayStyle: any = ListDisplayStyle;

	@ViewChild(UnoTableComponent)
	public table: UnoTableComponent;

	/**
	 * Layout of the table.
	 */
	@Input()
	public layout: UnoTableColumnLayout[] = [];

	/**
	 * Method to load more elements.
	 */
	@Input()
	public loadMore: (count: number, pageSize: number)=> Promise<{elements: any[], hasMore: boolean}> = null;

	/**
	 * Method to get the total number of items to be shown on the table.
	 */
	@Input()
	public totalItems: (()=> Promise<number>) | number = null;

	/**
	 * Number of elements to show on the table.
	 */
	@Input()
	public pageSize: number = 30;

	/**
	 * Whether or not you can see the checkbox to select each row.
	 */
	@Input()
	public selectable: boolean = true;

	/**
	 * Whether or not you can see the label before the row value.
	 */
	@Input()
	public labelShown: boolean = false;

	/**
	 * Handler for the mobile list
	 */
	@Input()
	public handler: UnoListLazyLoadHandler<any> = new UnoListLazyLoadHandler<any>();

	/**
	 * Whether or not you can select a row.
	 */
	@Input()
	public rowClickable: boolean = true;

	/**
	 * Emits an array indexes of the checked rows when a row selection is checked.
	 *
	 * If no row is selected emits false as value, if all rows are all selected emits true.
 	 */
	@Output()
	public rowChecked = new EventEmitter<{rows: number[] | boolean, items: any[] | boolean}>();

	/**
	 * Emits the element that was clicked.
 	 */
	@Output()
	public rowClick = new EventEmitter<{index: number, element: any}>();

	/**
	 * Emits the attribute to sort the api by.
 	 */
	@Output()
	public sortChange = new EventEmitter<{sortBy: string}>();

	/**
	 * Array of Checked Row items.
	 */
	public checkedItemList: any[] = [];

	/**
	 * Array of Checked Row indexes.
	 */
	public checkedIndexes: number[] = [];

	/**
	 * The table sort field.
	 */
	public sortField: string;

	/**
	 * The table sort direction.
	 */
	public sortDirection: string;

	public ngOnInit(): void {
		this.handler.loadMore = this.loadMore;
	}

	/**
	 * When a row is selected emit an array containing all the checked rows and Uuids(If they exist).
	 *
	 * @param item - The clicked item.
	 */
	public rowCheck(item: any): void {
		// Get the index of the item
		const index = this.checkedItemList.indexOf(item);

		// Check if the item is on the array of selected items.
		if (index === -1) {
			// Add to selection.
			this.checkedItemList.push(item);
			this.checkedIndexes.push(this.handler.items.indexOf(item));
		} else if (index !== -1) {
			// Remove from selection.
			this.checkedItemList.splice(index, 1);
			this.checkedIndexes.splice(index, 1);
		}

		this.rowChecked.emit({rows: this.checkedIndexes, items: this.checkedItemList});
	}

	/**
	 * Export the selected items, if none are selected export all items.
	 *
	 * @param filename - The name for the file.
	 */
	public async export(fileName?: string): Promise<void> {
		let checkedIndexes = null;
		if (App.device.isDesktop()) {
			this.table.export();
		} else {
			let totalItemCount: number;

			if (typeof this.totalItems === 'function') {
				totalItemCount = await this.totalItems();
			} else if (typeof this.totalItems === 'number') {
				totalItemCount = this.totalItems;
			}
			if (this.checkedIndexes.length === 0) {
				checkedIndexes = [...Array(totalItemCount).keys()];
			} else {
				checkedIndexes = this.checkedIndexes;
			}
			UnoTableExport.exportTableXLSX(this.loadMore, this.layout, checkedIndexes, totalItemCount, fileName);
		}
	}

	/**
	 * Gets a image by its string/Resource.
	 *
	 * @param image - Image (string or resource) to look for.
	 * @returns - Path to the image.
	 */
	public getImage(image: Resource | string = './assets/placeholder/asset.png'): string {
		if (typeof image === 'string') {
			return image;
		}
		
		const resource = image as Resource;
		return Service.getURL(ServiceList.resources.image.get, {
			uuid: resource.uuid,
			format: resource.format
		});
	}

	/**
	 * Reset the table and list, unload all elements and prepare to load new content.
	 */
	public async reset(): Promise<void> {
		this.checkedItemList = [];
		this.checkedIndexes = [];
		if (App.device.isDesktop()) {
			this.table.handler.sortDirection = this.sortDirection;
			this.table.handler.sortField = this.sortField;
			if (this.table) {
				await this.table.reset();
			}
		} else {
			await this.handler.reset();
		}
	}

	/**
	 * Emits the parameter to sort by.
	 *
	 * @param sortBy - The parameter to sort by.
	 */
	public sortBy(sortBy: string): void {
		this.sortDirection = this.table.handler.sortDirection;
		this.sortField = this.table.handler.sortField;
		this.sortChange.emit({sortBy: sortBy});
	}
}

