import {ActionPlanAction} from 'src/app/models/atex-inspections/action-plan/action-plan-action';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';

export type ActionPlanActionLoadResponse = {
	// Array of actions fetched from the api
	actions: ActionPlanAction[],
	// The request id
	id: number,
	// If there are more actions to fetch
	hasMore: boolean
}

export type ActionPlanActionLoadParams = {
	// Search value to filter by
	search?: string,
}

export class ActionPlanActionService {
	/**
	 * List of all possible Action Plan actions.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * 
	 * @returns The atex action plan actions that match the params.
	 */
	public static async load(params: ActionPlanActionLoadParams = {}, hideLoading: boolean = false, displayError: boolean = true): Promise<ActionPlanActionLoadResponse> {
		const request = await Service.fetch(ServiceList.atex.actionPlan.actionsGet, null, null, params, Session.session, hideLoading, displayError);

		return {
			actions: request.response.actions.map((action) => {return ActionPlanAction.parse(action);}),
			id: request.id,
			hasMore: false
		};
	}
}
