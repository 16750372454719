import {Vector3} from 'three';
import {ObjectUtils} from '../../../utils/object-utils';
import {Geolocation} from '../../../models/geolocation';
import {AtexInspectionField} from '../../../models/atex-inspections/inspections/atex-inspection-field';
import {DateFrequency} from '../../../models/date-frequency';
import {Atex} from '../../../models/atex/atex';
import {AtexInspectionFieldResult} from '../../../models/atex-inspections/inspections/atex-inspection-field-result';
import {UnoFormField} from './uno-form-field';
import {UnoFormFieldTypes} from './uno-form-field-types';

/**
 * Utils to manipulate data from forms.
 */
export class UnoFormUtils {
	/**
	 * Search form field by attribute. Searches also the underlying forms attached.
	 *
	 * @param layout - Layout of the form to search.
	 * @param attribute - Name of the attribute to get.
	 * @returns Field of the form for the attribute if present, null otherwise.
	 */
	public static getFormFieldByAttribute(layout: UnoFormField[], attribute: string): UnoFormField {
		for (let i = 0; i < layout.length; i++) {
			if (layout[i].attribute === attribute) {
				return layout[i];
			}

			if (layout[i].fields) {
				const field = UnoFormUtils.getFormFieldByAttribute(layout[i].fields, attribute);
				if (field !== null) {
					return field;
				}
			}
		}

		return null;
	}

	/**
	 * Checks if the field is a subform.
	 *
	 * @returns True if the field is a subform, false otherwise.
	 */
	public static isSubform(field: UnoFormField): boolean {
		return field.type === UnoFormFieldTypes.REPETITIVE_FORM || field.type === UnoFormFieldTypes.COMPOSED_FIELD || field.type === UnoFormFieldTypes.SUB_FORM;
	}

	/**
	 * Fetch options for the form fields that need to update them.
	 *
	 * Can fetch options for a single field or for all the form.
	 *
	 * @param layout - Form to get options for.
	 * @param object - Object attached to the form.
	 */
	public static fetchOptions(layout: UnoFormField[], object: any): void {
		for (let i = 0; i < layout.length; i++) {
			if (layout[i].fetchOptions !== undefined) {
				layout[i].fetchOptions(object, layout[i]);
			}
		}
	}

	/**
	 * Static method to get an object attribute to display in the form.
	 *
	 * @param object - The object to extract the attribute from.
	 * @param row - Row of the form to get, to read name of the attribute in the object.
	 * @param subAttribute - Sub attribute to access in the object.
	 * @returns
	 */
	public static getAttribute(object: any, row: UnoFormField, subAttribute?: string): any {
		if (!row.attribute) {
			return null;
		}

		let attribute = row.attribute;

		if (subAttribute) {
			attribute += '.' + subAttribute;
		}

		return ObjectUtils.getAttribute(object, attribute);
	}

	/**
	 * Static method to set an object attribute with a new value.
	 *
	 * Attribute names can indicate nested objects (e.g. `a:{b:{c:2}}` the c value can be accessed as "a.b.c").
	 *
	 * @param object - The object to set the attribute in.
	 * @param row - Row of the form to set, to get name of the attribute in the object.
	 * @param value - New value to be set.
	 * @param subAttribute - Sub attribute to access in the object.
	 * @returns True if the attribute was changed (was different from the original attribute in the object).
	 */
	public static setAttribute(object: any, row: UnoFormField, value: any, subAttribute?: string): boolean {
		let attrs = row.attribute.split('.');

		if (subAttribute !== undefined) {
			attrs = attrs.concat(subAttribute.split('.'));
		}

		let sub = object;
		let i = 0;
		for (i = 0; i < attrs.length - 1; i++) {
			sub = sub[attrs[i]];
		}

		// Only change attribute if value is different.
		if (sub[attrs[i]] !== value) {
			sub[attrs[i]] = value;
			return true;
		}

		return false;
	}

	/**
	 * Check if a specific field of an object is empty.
	 *
	 * @param row - Row of the form to be checked.
	 * @param object - Object to check values.
	 * @returns True if the field is empty false otherwise.
	 */
	public static fieldEmpty(row: UnoFormField, object: any): boolean {
		// Use row override method.
		if (row.isEmpty instanceof Function) {
			return row.isEmpty(object, row);
		}

		// Check some known possible values.
		const value = UnoFormUtils.getAttribute(object, row);

		return UnoFormUtils.valueEmpty(value, row.type);
	}

	/**
	 * Check if a value of a specific type is empty.
	 * 
	 * Receives the value to check (not the object attribute), and the type of the field.
	 * 
	 * @param value - Value to check if it is empty.
	 * @param type - Type of the field to check.
	 * @returns True if the value is empty, false otherwise.
	 */
	public static valueEmpty(value: any, type: UnoFormFieldTypes): boolean {
		if (value === null || value === undefined) {
			return true;
		}

		if (type === UnoFormFieldTypes.ATEX_FIELD) {
			return value.group === -1 && value.category === -1 && value.ambient === -1;
		} else if (type === UnoFormFieldTypes.ATEX_INSPECTION_FIELD) {
			return !value.notApplicable && (value.result === AtexInspectionFieldResult.NONE || !value.result);
		} else if (type === UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE) {
			return value.length === 0;
		} else if (type === UnoFormFieldTypes.DATE_FREQUENCY) {
			return DateFrequency.isEmpty(value);
		} else if (type === UnoFormFieldTypes.REPETITIVE_FORM) {
			return !value || value.length === 0;
		} else if (type === UnoFormFieldTypes.KEY_VALUE_ARRAY) {
			for (const val of value) {
				if (!val.value) {
					return true;
				}
			}
			return !value || value.length === 0;
		} else if (type === UnoFormFieldTypes.TEXT_LIST) {
			for (const val of value) {
				if (!val) {
					return true;
				}
			}
			return !value || value.length === 0;
		} else if (typeof value === 'string' || value instanceof Array) {
			return value.length === 0;
		}

		return false;
	}

	/**
	 * Create object to match the layout specified.
	 *
	 * @param layout - Form layout.
	 */
	public static createObject(layout: any[]): any {
		const object = {};

		for (let i = 0; i < layout.length; i++) {

			const attribute = layout[i].attribute;
			if (!attribute) {
				continue;
			}

			switch (layout[i].type) {
				case UnoFormFieldTypes.TEXT:
				case UnoFormFieldTypes.TEXT_MULTILINE:
				case UnoFormFieldTypes.PASSWORD:
				case UnoFormFieldTypes.EMAIL:
					object[attribute] = '';
					break;
				case UnoFormFieldTypes.CHECKBOX:
					object[attribute] = false;
					break;
				case UnoFormFieldTypes.NUMBER:
					object[attribute] = 0;
					break;
				case UnoFormFieldTypes.GEO_POSITION:
					object[attribute] = new Geolocation(0, 0);
					break;
				case UnoFormFieldTypes.ATEX_INSPECTION_FIELD:
					object[attribute] = new AtexInspectionField();
					break;
				case UnoFormFieldTypes.DATE_FREQUENCY:
					object[attribute] = new DateFrequency();
					break;
				case UnoFormFieldTypes.ATEX_FIELD:
					object[attribute] = new Atex();
					break;
				case UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE:
				case UnoFormFieldTypes.OPTIONS_MULTIPLE:
				case UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY:
				case UnoFormFieldTypes.TEXT_LIST:
				case UnoFormFieldTypes.REPETITIVE_FORM:
					object[attribute] = [];
					break;
				case UnoFormFieldTypes.VECTOR3:
					object[attribute] = new Vector3();
					break;
				case UnoFormFieldTypes.COMPOSED_FIELD:
				case UnoFormFieldTypes.SUB_FORM:
					object[attribute] = UnoFormUtils.createObject(layout[i].fields);
					break;
				default:
					object[attribute] = null;
					break;
			}
		}

		return object;
	}

	/**
	 * Check a condition that can be both a boolean value or a Function.
	 *
	 * Function should receive the object and row as parameters.
	 *
	 * @param value - Condition attribute to be checked.
	 * @param defaultValue - Default value to be used.
	 * @param object - Object attached to the form.
	 * @param row - Row of the form.
	 */
	public static checkBool(value: (boolean | Function), defaultValue: boolean, object: any, row: UnoFormField): boolean {
		if (value === undefined || value === null) {
			return defaultValue;
		}

		if (value instanceof Function) {
			return value(object, row) === true;
		}

		return value === true;
	}

	/**
	 * Create a generic layout for a javascript object using the base fields.
	 *
	 * @param object - Object to create the layout for.
	 * @returns Layout object to use for the form.
	 */
	public static createLayout(object: any): any[] {
		const layout = [];

		for (const i in object) {
			let type = null;

			if (object[i] instanceof DateFrequency) {
				type = UnoFormFieldTypes.DATE_FREQUENCY;
			} else if (object[i] instanceof Geolocation) {
				type = UnoFormFieldTypes.GEO_POSITION;
			} else if (object[i] instanceof AtexInspectionField) {
				type = UnoFormFieldTypes.ATEX_INSPECTION_FIELD;
			} else if (object[i] instanceof Atex) {
				type = UnoFormFieldTypes.ATEX_FIELD;
			} else if (typeof object[i] === 'string') {
				type = UnoFormFieldTypes.TEXT;
			} else if (typeof object[i] === 'number') {
				type = UnoFormFieldTypes.NUMBER;
			} else if (typeof object[i] === 'boolean') {
				type = UnoFormFieldTypes.CHECKBOX;
			}

			if (type !== null) {
				layout.push({
					attribute: i,
					type: UnoFormFieldTypes.TEXT
				});
			}
		}

		return layout;
	}

	/**
	 * Method to check attributes/subattribute equality between two objects for a specific field/row in the form.
	 *
	 * @param a - One of the objects to compare.
	 * @param b - The other object to compare.
	 * @param row - Row of the form to check.
	 */
	public static equal(a: any, b: any, row: UnoFormField): boolean {
		if (!a && b || !row.attribute) {
			return false;
		}

		return ObjectUtils.equal(UnoFormUtils.getAttribute(a, row), UnoFormUtils.getAttribute(b, row));
	}
}
