import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoFilterBarComponent, UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {APAssetFormBlockListResponse, AssetFormBlockListParams, AssetFormBlockService} from 'src/app/modules/asset-portfolio/services/asset-form-block.service';
import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {UnoListComponent} from '../../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {PermissionsPipe} from '../../../../../../pipes/permissions.pipe';

/**
 * Page used to list form blocks.
 */
@Component({
	selector: 'form-block-list-page',
	templateUrl: 'asset-form-block-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		UnoResponsiveTableListComponent,
		UnoFilterBarComponent,
		PermissionsPipe
	]
})
export class AssetFormBlockListPage extends ScreenComponent implements OnInit {
	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public app: any = App;

	public selfStatic: any = AssetFormBlockListPage;

	public permissions = [UserPermissions.ASSET_PORTFOLIO];

	public session: any = Session;

	public userPermissions: any = UserPermissions;
	
	/**
	 * The maximum number of items to show on table component.
	 */
	public totalItems: number = 1;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;
	
	/**
	 * The layout to use on the Uno Table component.
	 */
	public layout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', sortBy: '[ap_form_block].[name]'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'small'},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			icons: [
				{
					src: './assets/icons/assets/expand-icon.svg',
					click: (row: any): void => {
						App.openInTab('/menu/asset-portfolio/structure/form-block/edit', {uuid: row.uuid});

					}
				}
			]
		}
	];

	/**
	 * Possible database filter to be used for ordering the Asset Form Block list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[ap_form_block].[name]',
			options: [
				{label: 'name', value: '[ap_form_block].[name]'},
				{label: 'createdAt', value: '[ap_form_block].[created_at]'},
				{label: 'updatedAt', value: '[ap_form_block].[updated_at]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[ap_form_block].[name]', '[ap_form_block].[description]'],
			multiple: true,
			options: [
				{label: 'name', value: '[ap_form_block].[name]'},
				{label: 'description', value: '[ap_form_block].[description]'}
			]
		}
	];
		
	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Text used to filter assetFormBlocks by their name, description or UUID.
		 */
		search: '',

		/**
		 * Search fields to be considered(name should match database column)
		 */
		searchFields: []
	}, AssetFormBlockListPage.filterOptions);

	public loadMore = async(count: number, pageSize: number): Promise<any> => {

		const params: AssetFormBlockListParams = {
			from: count,
			count: pageSize,
			search: AssetFormBlockListPage.filters.search,
			sortDirection: AssetFormBlockListPage.filters.sortDirection,
			sortField: AssetFormBlockListPage.filters.sortField,
			searchFields: AssetFormBlockListPage.filters.searchFields
		};

		const list: APAssetFormBlockListResponse = await AssetFormBlockService.list(params);

		return {
			elements: list.blocks,
			hasMore: list.hasMore
		};
	};

	public static defaultFilters = structuredClone(AssetFormBlockListPage.filters);

	public resetFilters(): void {
		Object.assign(AssetFormBlockListPage.filters, AssetFormBlockListPage.defaultFilters);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		App.navigator.setTitle('formBlocks');
	}

	/**
	 * Update filters and reload data from the API.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		AssetFormBlockListPage.filters = filters;

		this.table.sortDirection = AssetFormBlockListPage.filters.sortDirection;
		this.table.sortField = AssetFormBlockListPage.filters.sortField;

		if (this.table) {
			await this.table.reset();
		}

		this.totalItems = await AssetFormBlockService.count(AssetFormBlockListPage.filters);
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		AssetFormBlockListPage.filters.search = event;
		this.totalItems = await AssetFormBlockService.count(AssetFormBlockListPage.filters);
		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * Change the sort direction/field used in the api list.
	 * 
	 * @param sortBy - Attribute to sort by.
	 */
	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === AssetFormBlockListPage.filters.sortField) {
			AssetFormBlockListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			AssetFormBlockListPage.filters.sortField = sortBy;
			AssetFormBlockListPage.filters.sortDirection = SortDirection.ASC;
		}

		if (this.table) {
			await this.table.reset();
		}
	}
}
