import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Repair} from 'src/app/models/repairs/repairs/repair';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';

export type RepairListResponse = {
	// If there are more repairs to fetch
	hasMore: boolean,
	// Repair List
	repairs: Repair[],
	// Request id
	id: number
}

export type RepairListParams = {
	// Status of the repair.
	status?: number,
	// Search value to apply on the request
	search?: string,
	// Fields to search by on the request
	searchFields?: string[],
	// Where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort direction to apply on the request
	sortDirection?: string,
	// Field to sort by
	sortField?: string,
	// Longevity of the repair
	longevityFilters?: string[],
	// Asset fluid family uuid to filter by
	assetFluidFamilyUuid?: UUID,
	// Asset fluid type uuid to filter by
	assetFluidTypeUuid?: UUID
};

export type RepairCountParams = {
	// Status of the repair.
	status?: number,
	// Search value to apply on the request
	search?: string,
	// Fields to search by on the request
	searchFields?: string[],
	// Longevity of the repair
	longevityFilters?: string[],
	// Asset fluid family uuid to filter by
	assetFluidFamilyUuid?: UUID,
	// Asset fluid type uuid to filter by
	assetFluidTypeUuid?: UUID
};

export class RepairService {
	/**
	 * Get the repair by UUID.
	 *
	 * @param uuid - The UUID of the repair to get.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Repair object.
	 */
	public static async get(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<Repair> {
		const request = await Service.fetch(ServiceList.repairs.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return Repair.parse(request.response.repair);
	}

	/**
	* Get the repair by QR code.
	*
	* @param qr - The QR code of the repair to get.
	* @param hideLoading - True to hide the loading spinner, false otherwise.
	* @param displayError - True to display an error message, false otherwise.
	* @returns Repair object.
	*/
	public static async getByQR(qr: string, hideLoading: boolean = false, displayError: boolean = true): Promise<Repair> {
		const request = await Service.fetch(ServiceList.repairs.getQR, null, null, {qr: qr}, Session.session, hideLoading, displayError);
		return Repair.parse(request.response.repair);
	}

	/**
	 * List all Repairs meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The Repairs in the database that match the params.
	 */
	public static async list(params: RepairListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<RepairListResponse> {
		const request = await Service.fetch(ServiceList.repairs.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			repairs: request.response.repairs.map((data: any) => {return Repair.parse(data);}),
			id: request.id
		};
	}

	/**
	 * Count all Repairs meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The number of Repairs in the database that match the params.
	 */
	public static async count(params: RepairCountParams, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		return (await Service.fetch(ServiceList.repairs.count, null, null, params, Session.session, hideLoading, displayError)).response.count;
	}
}
