import {Session} from 'src/app/session';
import {UUID} from 'src/app/models/uuid';
import {DL50Question} from '../../../models/dl50/masterdata/dl50-question';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {SortUtils} from '../../../utils/sort-utils';
import {SortDirection} from '../../../utils/sort-direction';
import {CompareUtils} from '../../../utils/compare-utils';

// Parameters to apply on the DL50 questions count API request.
export type DL50QuestionsCountParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[]
};

// Parameters to apply on the DL50 questions list API request.
export type DL50QuestionsListParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
};

// DL50 questions list API request format.
export type DL50QuestionsListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched questions
	questions: DL50Question[]
};


export class DL50QuestionsService {
	/**
	 * Create a new DL-50 master data question.
	 *
	 * @param params - The params to apply on the api request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The UUID of the created DL-50 master data question.
	 */
	public static async create(params: DL50Question, hideLoading: boolean = false, displayError: boolean = true): Promise<UUID> {
		return (await Service.fetch(ServiceList.dl50.masterdata.questions.create, null, null, params, Session.session, hideLoading, displayError)).response.uuid;
	}

	/**
	 * Update a DL-50 master data question with received data.
	 *
	 * @param question - The question to update.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async update(question: DL50Question, hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		await Service.fetch(ServiceList.dl50.masterdata.questions.update, null, null, question, Session.session, hideLoading, displayError);
	}

	/**
	 * Get a DL-50 master data question by its UUID.
	 *
	 * @param uuid - The uuid of the dl50 master data question to get.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The DL-50 master data question that matches the uuid.
	 */
	public static async get(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<DL50Question> {
		const request = await Service.fetch(ServiceList.dl50.masterdata.questions.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return DL50Question.parse(request.response.question);
	}

	/**
	 * Get a batch DL-50 master data questions by UUIDs.
	 *
	 * @param uuids - The uuids to fetch for.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 *
	 * @returns Array of questions.
	 */
	public static async getBatch(uuids: UUID[], hideLoading: boolean = false, displayError: boolean = true): Promise<DL50Question[]> {
		const request = await Service.fetch(ServiceList.dl50.masterdata.questions.getBatch, null, null, {uuids: uuids}, Session.session, hideLoading, displayError);
		return request.response.questions.map((q: any) => {return DL50Question.parse(q);});
	}

	/**
	 * Get a DL-50 master data question by question response UUID.
	 *
	 * @param uuid - The uuid of the question response to get the question for.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * 
	 * @returns The DL-50 master data question that matches the question response uuid.
	 */
	public static async getByResponseID(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<DL50Question> {
		const request = await Service.fetch(ServiceList.dl50.masterdata.questions.getByResponse, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return DL50Question.parse(request.response.question);
	}

	/**
	 * Update multiple DL-50 master data questions in batch.
	 *
	 * @param questions - The DL-50 master data questions to update.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async updateBatch(questions: DL50Question[], hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		await Service.fetch(ServiceList.dl50.masterdata.questions.updateBatch, null, null, {questions: questions}, Session.session, hideLoading, displayError);
	}

	/**
	 * Count questions matching the params.
	 *
	 * @param params - The params to apply on the api request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * 
	 * @returns The number of DL-50 master data questions that match the params.
	 */
	public static async count(params: DL50QuestionsCountParams = {}, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		return (await Service.fetch(ServiceList.dl50.masterdata.questions.count, null, null, params, Session.session, hideLoading, displayError)).response.count;
	}

	/**
	 * List questions matching the params.
	 *
	 * @param params - The params to apply on the api request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * 
	 * @returns Array of questions.
	 */
	public static async list(params: DL50QuestionsListParams = {}, hideLoading: boolean = false, displayError: boolean = true): Promise<DL50QuestionsListResponse> {
		const request = await Service.fetch(ServiceList.dl50.masterdata.questions.list, null, null, params, Session.session, hideLoading, displayError);

		const questions = request.response.questions.map((q: any) => {
			return DL50Question.parse(q);
		});

		return {
			hasMore: request.response.hasMore,
			questions: SortUtils.sortList(questions, 'index', CompareUtils.compareNumber, SortDirection.ASC)
		};
	}

	/**
	 * Delete a question by its UUID.
	 *
	 * @param uuid - The uuid of the Dl50 master data question to delete.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async delete(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		await Service.fetch(ServiceList.dl50.masterdata.questions.delete, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
	}
}
