import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {DOMUtils} from 'src/app/utils/dom-utils';
import {CssNgStyle} from 'src/app/utils/css-ng-style';
import {CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf} from '@angular/cdk/scrolling';
import {ResizeDetector} from 'src/app/utils/resize-detector';
import {Locale} from 'src/app/locale/locale';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {NgStyle, NgClass} from '@angular/common';
import {SortDirection} from 'src/app/utils/sort-direction';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Resource} from '../../../models/resource';
import {UUID} from '../../../models/uuid';
import {UnoIconComponent} from '../uno-icon/uno-icon.component';
import {UnoNoDataComponent} from '../uno-no-data/uno-no-data.component';
import {ListDisplayStyle} from '../uno-responsive-table-list/uno-responsive-table-list.component';
import {FormatDatePipe} from '../../../pipes/format-date.pipe';
import {UnoTableExport} from './uno-table-export';
import {UnoLazyUnorderedLoaded} from './uno-lazy-unordered-loaded';

/**
 * The different types that are accepted by the table
 */
export enum UnoTableColumnType {
	/**
	 * Display a number (Optionally also show a icon).
	 */
	NUMBER = 1,

	/**
	 * Display text.
	 */
	TEXT = 2,

	/**
	 * Display a status indicator.
	 */
	STATUS = 3,

	/**
	 * Display an image.
	 */
	IMAGE = 4,

	/**
	 * Display a clickable icon to redirect to a new page.
	 */
	ICONS = 5,

	/**
	 * Display a date.
	 */
	DATE = 6,
};


/**
 * Layout to use in conjunction with the content attribute.
 */
export class UnoTableColumnLayout {
	/**
	 * Title of the column, presented in the header.
	 */
	public header: string;

	/**
	 * Indicates the type of the column (e.g. text, number, checkbox)
	 */
	public type: (typeof UnoTableColumnType)[keyof typeof UnoTableColumnType];

	/**
	 * The name of the attribute that will be mapped to this columns from the object.
	 *
	 * Attribute can refer to a sub-object or index (e.g a.b.c)
	 */
	public attribute: string;

	/**
	 * Either to present of hide this column when the table is first shown.
	 *
	 * If set false the user has to enable this column manually.
	 */
	public visible: boolean = true;

	/**
	 * The size of the column ('small': checkbox, status, single words, 'medium': UUID's, titles or 'large': descriptions), defaults to small.
	 */
	public size: string = 'small';

	/**
	 * The minimum width of the column.
	 */
	public minWidth?: string = '65px';

	/**
	 * The tag to use on each row (applied to responsive table list)
	 */
	public tag?: ListDisplayStyle = 3;

	/**
	 * The attribute to sort the api results by.
	 */
	public sortBy?: string;

	/**
	 * How many icons to show on the table
	 */
	public iconNumber?: number = 1;

	/**
	 * Icons to present on the column.
	 */
	public icons?: {src: String, click: (row: any)=> void}[];
}

/**
 * The table component, receives a layout and content attributes to build the table.
 */
@Component({
	selector: 'uno-table',
	templateUrl: './uno-table.component.html',
	styleUrls: ['./uno-table.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		NgStyle,
		NgClass,
		CdkVirtualScrollViewport,
		CdkFixedSizeVirtualScroll,
		CdkVirtualForOf,
		FormsModule,
		UnoIconComponent,
		TranslateModule,
		UnoNoDataComponent,
		FormatDatePipe
	]
})
export class UnoTableComponent implements OnChanges, OnDestroy, OnInit, AfterViewInit {

	@ViewChild(CdkVirtualScrollViewport, {static: true})
	public scrollViewport: CdkVirtualScrollViewport;

	public get locale(): any {return Locale;}

	public get types(): typeof UnoTableColumnType {return UnoTableColumnType;}

	public get sortDirection(): typeof SortDirection {return SortDirection;}

	/**
	 * Layout of the table.
	 */
	@Input()
	public layout: UnoTableColumnLayout[] = [];

	/**
	 * Method to load more elements.
	 */
	@Input()
	public loadMore: (count: number, pageSize: number)=> Promise<{elements: any[], hasMore: boolean}> = null;

	/**
	 * Method to get the total number of items to be shown on the table.
	 */
	@Input()
	public totalItems: (()=> Promise<number>) | number = null;

	/**
	 * Number of elements to show on the table.
	 */
	@Input()
	public pageSize: number = 30;

	/**
	 * Whether you can see the checkbox to select each row.
	 */
	@Input()
	public selectable: boolean = true;

	/**
	 * Whether you can select a row.
	 */
	@Input()
	public rowClickable: boolean = true;

	/**
	 * Emits an array indexes of the checked rows when a row selection is checked.
	 *
	 * If no row is selected emits false as value, if all rows are all selected emits true.
 	 */
	@Output()
	public rowChecked = new EventEmitter<{rows: number[] | boolean, items: UUID[] | boolean}>();

	/**
	 * Emits the element that was clicked.
 	 */
	@Output()
	public rowClick = new EventEmitter<{index: number, element: any}>();

	/**
	 * Emits the attribute to sort the api by.
 	 */
	@Output()
	public sortChange = new EventEmitter<{sortBy: string}>();

	/**
	 * Total number of items to be shown on the table.
	 */
	public totalItemCount: number = 0;

	/**
	 * The currently active page, starting from 0.
	 *
	 * To be displayed in the GUI.
	 */
	public currentPage: number = 0;

	/**
	 * Wether the "select all" button is active or not.
	 */
	public allSelected: boolean = false;

	/**
	 * List of all checked rows.
	 */
	public checkedRows: boolean[] = [];

	/**
	 * List of all indexes checked in the list.
	 */
	public checkedIndexes: number[] = [];

	/**
	 * List of all items checked in the list.
	 */
	public checkedItems: any[] = [];

	/**
	 * The currently topmost shown item.
	 */
	public currentItem: number;

	/**
	 * Lazy list loading handler.
	 */
	public handler: UnoLazyUnorderedLoaded<any> = null;

	/**
	 * Resize observer
	 */
	public resize: ResizeDetector = null;

	public constructor(private ref: ChangeDetectorRef) {
		this.handler = new UnoLazyUnorderedLoaded();
	}

	public ngOnDestroy(): void {
		this.resize.destroy();
	}

	public ngOnInit(): void {
		this.resize = new ResizeDetector(this.scrollViewport.elementRef.nativeElement, () => {
			this.checkItems();
		});
	}

	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.totalItems) {
			if (typeof this.totalItems === 'function') {
				this.totalItemCount = await this.totalItems();
			} else if (typeof this.totalItems === 'number') {
				this.totalItemCount = this.totalItems;
			} else {
				throw new Error('totalItems must be number or function.');
			}
		}

		if (changes.pageSize || changes.totalItems || changes.loadMore) {
			this.handler.pageSize = this.pageSize;
			this.handler.total = this.totalItemCount;
			this.handler.loadMore = this.loadMore;
			await this.reset();
		}
	}

	/**
	 * Reset the table element, unload all elements and prepare to load new content.
	 */
	public async reset(): Promise<void> {
		await this.handler.reset();

		this.checkedRows = new Array(this.totalItemCount).fill(false);

		this.fillChecked();

		this.scrollViewport.scrollToIndex(0);
	}

	public async ngAfterViewInit(): Promise<void> {
		await DOMUtils.waitUntilRendered(this.scrollViewport.elementRef.nativeElement);

		this.scrollViewport.checkViewportSize();

		const end = this.scrollViewport.getRenderedRange().end;
		this.currentPage = this.handler.itemPage(end);
		this.currentItem = end;

		this.checkItems();

		for (const attribute of this.layout) {
			if (attribute.type === UnoTableColumnType.ICONS && (attribute.iconNumber || attribute.icons?.length > 0)) {
				attribute.minWidth = attribute.iconNumber ? attribute.iconNumber * 32 + 'px' : attribute.icons.length * 32 + 'px';
				(document.querySelector(String('[aria-label="' + attribute.attribute + '"]')) as HTMLElement).style.width = attribute.minWidth;
			}
		}
	}

	/**
	 * Method to check items currently displayed on the table.
	 *
	 * Checks the pages that are visible and loads any missing page.
	 */
	public async checkItems(): Promise<void> {
		const range = this.scrollViewport.getRenderedRange();

		for (let page = this.handler.itemPage(range.start); page <= this.handler.itemPage(range.end); page++) {
			if (!this.handler.pageLoaded(page)) {
				await this.handler.loadPage(page);
			}
		}

		this.fillChecked();
		this.currentItem = range.end;
		this.currentPage = this.handler.itemPage(range.end);
		this.ref.detectChanges();
	}

	/**
	 * Toggles all of the rows to be selected or deselected.
	 *
	 * @param allSelected - If true all elements are selected, if false all elements are deselected.
	 */
	public selectAll(allSelected: boolean): void {
		if (allSelected) {
			(document.querySelector('[aria-label="selectAll"]') as HTMLInputElement).checked = true;
		}

		if (!this.selectable) {
			throw new Error('Cannot selected element when selectable is set false.');
		}

		this.allSelected = allSelected;

		this.checkedRows.fill(this.allSelected);
		this.checkedIndexes = [];
		this.checkedItems = [];

		this.rowChecked.emit({rows: this.allSelected, items: this.allSelected});
	}

	/**
	 * When a row is selected emit an array containing all the checked rows and Uuids(If they exist).
	 *
	 * @param row - The row object that was selected.
	 * @param index - the row index that was selected.
	 */
	public select(row: any, index: number): void {
		this.allSelected = false;
		(document.querySelector('[aria-label="selectAll"]') as HTMLInputElement).checked = false;
		if (!this.selectable) {
			throw new Error('Cannot selected element when selectable is set false.');
		}

		if (this.checkedItems.includes(row)) {
			this.checkedItems.splice(this.checkedItems.indexOf(row), 1);
			this.checkedIndexes.splice(this.checkedIndexes.indexOf(index), 1);
		} else {
			this.checkedItems.push(row);
			this.checkedIndexes.push(index);
			this.checkedIndexes.sort();
		}

		if (this.checkedIndexes.length === this.totalItemCount) {
			this.selectAll(true);
		}

		if (this.checkedIndexes.length === 0) {
			this.rowChecked.emit({rows: false, items: false});
		} else {
			this.rowChecked.emit({rows: this.checkedIndexes, items: this.checkedItems});
		}
	}

	/**
	 * Changes to the specified page
	 *
	 * @param page - The page number to navigate to.
	 */
	public changePage(page: number): void {
		// Verify if the page is bellow 0
		const lastPage = this.handler.itemPage(this.totalItemCount);
		page = page < 0 ? 0 : page > lastPage ? lastPage : page;

		// Scroll to the correct index.
		this.scrollViewport.scrollToIndex(page * this.pageSize);
	}

	/**
	 * Gets the style for the element given the row layout and wether its for the header or not.
	 * @param layout - Layout of the row.
	 * @param header - If the style is for the header of the table.
	 * @returns The style to apply on the element.
	 */
	public getStyle(layout: any, header: boolean = false): CssNgStyle {
		let style: CssNgStyle;

		if (layout.type === this.types.TEXT || layout.type === this.types.DATE) {
			style = {
				display: layout.visible ? 'flex' : 'none',
				'flex-basis': '40px',
				'flex-shrink': layout.size === 'large' ? 2 : 1,
				'flex-grow': layout.size === 'large' ? 2 : 1,
				'padding-left': header ? '10px' : 0,
				'min-width': layout.minWidth ? layout.minWidth : '65px',
				cursor: layout.sortBy || this.rowClickable && !header ? 'pointer' : 'auto'
			};
		} else {
			style = {
				display: layout.visible ? 'flex' : 'none',
				'flex-basis': layout.type === this.types.NUMBER ? '120px' : '40px',
				'flex-shrink': 0,
				'flex-grow': 0,
				'padding-left': 0,
				'min-width': layout.minWidth ? layout.minWidth : '65px',
				cursor: layout.sortBy || this.rowClickable && !header ? 'pointer' : 'auto'
			};
		}
		return style;
	}

	/**
	 * Export table values as XLSX file.
	 *
	 * @param fileName - The name to apply on the file.
	 */
	public export(fileName?: string): void {
		const checkedIndexes = [...Array(this.checkedRows.length).keys()];
		
		UnoTableExport.exportTableXLSX(this.loadMore, this.layout, checkedIndexes, this.totalItemCount, fileName);
	}

	/**
	 * When a row is clicked, emit the index and element that was clicked.
	 *
	 * @param index - The index of the clicked element.
	 * @param element - The element that was clicked.
	 */
	public rowClicked(index, element): void {
		if (this.rowClickable) {
			this.rowClick.emit({index: index, element: element});
		}
	}

	/**
	 * Gets a image by its string/Resource
	 *
	 * @param image - Image (string or resource) to look for
	 * @returns Path to the image
	 */
	public getImage(image): string {
		if (typeof image === 'string' || !image) {
			return image ?? './assets/placeholder/asset.png';
		}

		const resource = image as Resource;

		return Service.getURL(ServiceList.resources.image.get, {
			uuid: resource.uuid,
			format: resource.format
		});
	}

	/**
	 * Emits the parameter to sort by.
	 *
	 * @param sortBy - The parameter to sort by.
	 */
	public sortBy(sortBy: string): void {
		if (this.handler.sortField === sortBy) {
			this.handler.sortDirection = this.handler.sortDirection === this.sortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
		} else {
			this.handler.sortDirection = SortDirection.ASC;
			this.handler.sortField = sortBy;
		}
		this.sortChange.emit({sortBy: sortBy});
	}

	/**
	 * Checks the handler for rows that havent been checked correctly.
	 */
	public fillChecked(): void {
		for (const [key, value] of Object.entries(this.handler.items)) {
			if (this.checkedItems.findIndex((el) => {return el?.uuid === value?.uuid;}) !== -1 && !this.checkedRows[key]) {
				this.checkedRows[key] = true;
			}
		}
	}
}
