import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoTableColumnLayout, UnoTableColumnType, UnoTableComponent} from 'src/app/components/uno/uno-table/uno-table.component';
import {RoleService} from 'src/app/modules/roles/services/role.service';
import {CompanyService} from 'src/app/modules/companies/services/companies.service';
import {UnoFilterBarComponent, UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {Locale} from 'src/app/locale/locale';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {App} from '../../../../app';
import {Resource} from '../../../../models/resource';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {ServiceSync} from '../../../../http/service-sync';
import {UnoListLazyLoadHandler} from '../../../../components/uno/uno-list/uno-list-lazy-load-handler';
import {UnoListComponent} from '../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UserService, UserServiceListParams} from '../../services/users.service';
import {ResourceUtils} from '../../../../utils/resource-utils';

	
export enum UserActiveFilter {
	ALL = 0,
	ACTIVE = 1,
	INACTIVE = 2
};

@Component({
	selector: 'users-list-page',
	templateUrl: 'user-list-page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		UnoTableComponent,
		UnoFilterBarComponent
	]
})
export class UserListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoTableComponent) 
	public table: UnoTableComponent;

	public app: any = App;

	public resource: any = Resource;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public selfStatic: any = UserListPage;

	public permissions = [UserPermissions.USER];

	/**
	 * Possible sort direction values.
	 */
	public static sortDirectionValues: any = [SortDirection.ASC, SortDirection.DESC];

	/**
	 * Possible database filter to be used for ordering the users list.
	 */
	public static sortFieldValues: any = [
		{label: 'name', attribute: 'name'},
		{label: 'email', attribute: 'email'},
		{label: 'createdAt', attribute: 'created_at'},
		{label: 'updatedAt', attribute: 'updated_at'}
	];

	/**
	 * Possible database filter to be used for ordering the user roles list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: 'name',
			options: [
				{label: 'name', value: 'name'},
				{label: 'email', value: 'email'},
				{label: 'createdAt', value: 'created_at'},
				{label: 'updatedAt', value: 'updated_at'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'filterActive',
			label: 'active',
			default: UserActiveFilter.ALL,
			options: [
				{label: 'all', value: UserActiveFilter.ALL},
				{label: 'active', value: UserActiveFilter.ACTIVE},
				{label: 'inactive', value: UserActiveFilter.INACTIVE}
			]
		}
	];

	/**
	 * Lazy list loading handler.
	 */
	public handler: UnoListLazyLoadHandler<any> = new UnoListLazyLoadHandler<any>();

	/**
	 * Object to synchronize service requests.
	 */
	public serviceSync: ServiceSync = new ServiceSync();

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 0;

	/**
	 * The number of items to show on table per page.
	 */
	public static tablePageSize: number = 30;

	/**
	 * The index of the rows that are checked in the table
	 */
	public checkedTableRows: number[] = [];

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'picture', type: UnoTableColumnType.IMAGE, attribute: 'picture', visible: true, size: 'small'},
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small'},
		{header: 'email', type: UnoTableColumnType.TEXT, attribute: 'email', visible: true, size: 'small'},
		{header: 'company', type: UnoTableColumnType.TEXT, attribute: 'company', visible: true, size: 'small'},
		{header: 'userRoles', type: UnoTableColumnType.TEXT, attribute: 'roles', visible: true, size: 'small'}
	];

	public ngOnInit(): void {
		super.ngOnInit();

		App.navigator.setTitle('users');

		this.handler.loadMore = async(count: number, pageSize: number) => {
			const data = {
				sortDirection: UserListPage.filters.sortDirection,
				sortField: UserListPage.filters.sortField,
				from: count,
				count: pageSize,
				search: UserListPage.filters.search,
				filterActive: UserListPage.filters.filterActive
			};

			const request = await this.serviceSync.fetch(ServiceList.users.list, null, null, data, Session.session);
			const response = request.response;

			return {
				elements: response.users,
				hasMore: response.hasMore
			};
		};
		if (App.device.isMobile()) {
			this.handler.reset();
		}
	}

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const data: UserServiceListParams = {
			sortDirection: UserListPage.filters.sortDirection,
			sortField: UserListPage.filters.sortField,
			from: count,
			count: pageSize,
			search: UserListPage.filters.search,
			filterActive: UserListPage.filters.filterActive
		};

		const list = await UserService.listUsers(data);
		const users = [];

		for (const user of list.users) {
			// Roles list
			const roles: string[] = [];
			for (const role of user.roleUuids) {
				roles.push((await RoleService.get(role)).name);
			}

			// Add to list
			users.push({
				uuid: user.uuid,
				picture: user.picture,
				name: user.name,
				email: user.email,
				company: user.companyUuid ? (await CompanyService.get(user.companyUuid)).name : '',
				roles: user.isAdmin ? [Locale.get('administrator')] : roles.length > 0 ? roles.join(', ') : ''
			});
		}

		return {
			elements: users,
			hasMore: list.hasMore
		};
	};

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Role of user to filter on the list.
		 */
		filterRole: -1,

		/**
		 * Text used to filter users by their email.
		 */
		search: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: UserListPage.sortDirectionValues[1],

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: UserListPage.sortFieldValues[0].attribute,

		/**
		 * Filter if the user is active.
		 */
		filterActive: UserActiveFilter.ACTIVE
	}, UserListPage.filterOptions);

	public static defaultFilters = structuredClone(UserListPage.filters);

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value
	 */
	public async onSearchChange(search: string): Promise<void> {
		UserListPage.filters.search = search;

		this.serviceSync.reset();
		await this.handler.reset();
		if (this.table) {
			await this.table.reset();
		}
		this.tableTotalItemsCount = await UserService.countUsers(UserListPage.filters);
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		UserListPage.filters = filters;

		this.table.handler.sortDirection = UserListPage.filters.sortDirection;
		this.table.handler.sortField = UserListPage.filters.sortField;

		this.serviceSync.reset();
		await this.handler.reset();
		if (this.table) {
			await this.table.reset();
		}
		this.tableTotalItemsCount = await UserService.countUsers(UserListPage.filters);
	}

	/**
	 * When the table emits a value to change which column to sort by.
	 * 
	 * @param attribute - The attribute to sort by.
	 */
	public async sortChanged(attribute: string): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (attribute === UserListPage.filters.sortField) {
			UserListPage.filters.sortDirection = this.table.handler.sortDirection;
		} else {
			UserListPage.filters.sortField = attribute;
			UserListPage.filters.sortDirection = SortDirection.ASC;
		}

		this.handler.reset();
		if (this.table) {
			await this.table.reset();
		}
	}

	protected readonly ResourceUtils = ResourceUtils;
}
