import {InspectionData} from 'src/app/models/inspections/inspection/inspection-data';
import {UUID} from '../../../models/uuid';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {Inspection} from '../../../models/inspections/inspection/inspection';

export type InspectionServiceListDetailed = {
	from?: number,
	count?: number,
	search?: string,
	searchFields?: string[],
	sortField?: string,
	sortDirection?: string,
	fetchData?: boolean,
	project?: UUID
};

export type InspectionServiceList = {
	from?: number,
	count?: number,
	search?: string,
	searchFields?: string[],
	sortField?: string,
	sortDirection?: string
};

export type InspectionServiceCount = {
	projectUuid?: UUID,
	stepUuid?: UUID,
	search?: string,
	searchFields?: string[]
};

export class InspectionService {
	/**
	 * Delete a inspection.
	 *
	 * @param uuid - UUID of the inspection.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async delete(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		await Service.fetch(ServiceList.inspection.delete, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
	}

	/**
	 * Load the inspection data from the API by UUID.
	 *
	 * @param uuid - The UUID of the inspection to load.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Inspection loaded from the API.
	 */
	public static async get(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<Inspection> {
		const request = await Service.fetch(ServiceList.inspection.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return Inspection.parse(request.response.inspection);
	}

	/**
	 * Get inspection by QR code.
	 *
	 * @param qr - QR code to search for.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Inspection loaded from the API.
	 */
	public static async getByQR(qr: string, hideLoading: boolean = false, displayError: boolean = true): Promise<Inspection> {
		const request = await Service.fetch(ServiceList.inspection.getQR, null, null, {qr: qr}, Session.session, hideLoading, displayError);
		return Inspection.parse(request.response.inspection);
	}

	/**
	 * List inspections with all data associated.
	 *
	 * @param params - Parameters to filter the list.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The inspections in the database that match the params.
	 */
	public static async listDetailed(params: InspectionServiceListDetailed = {}, hideLoading: boolean = false, displayError: boolean = true): Promise<{inspections: Inspection[], hasMore: boolean}> {
		const request = await Service.fetch(ServiceList.inspection.listDetailed, null, null, params, Session.session, hideLoading, displayError);

		const inspections: Inspection[] = request.response.inspections.map(function(i: any) {
			return Inspection.parse(i);
		});

		return {
			inspections: inspections,
			hasMore: request.response.hasMore
		};
	}

	/**
	 * List all inspections that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The inspections in the database that match the params.
	 */
	public static async list(params: InspectionServiceList = {}, hideLoading: boolean = false, displayError: boolean = true): Promise<{hasMore: boolean, id: number, inspections: any[]}> {
		const request = await Service.fetch(ServiceList.inspection.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			id: request.id,
			inspections: request.response.inspections
		};
	}

	/**
	 * Count all inspections that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The number of inspections in the database that match the params.
	 */
	public static async count(params: InspectionServiceCount = {}, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		const request = await Service.fetch(ServiceList.inspection.count, null, null, params, Session.session, hideLoading, displayError);

		return request.response.count;
	}

	/**
	 * Load the inspection data from the API by UUID.
	 *
	 * @param uuid - The UUID of the inspection to load.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Inspection data loaded from the API.
	 */
	public static async getInspectionData(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<InspectionData> {
		const request = await Service.fetch(ServiceList.inspection.inspectionData.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return InspectionData.parse(request.response.data);
	}
}
