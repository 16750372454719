import {Component, OnInit} from '@angular/core';
import {RepairInspectionStatus} from '../../../../../models/repairs/inspections/repair-inspection-status';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {Session} from '../../../../../session';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {MenuCardsGroup, MenuCardsGroupsComponent} from '../../../../../components/layout/menu-cards-groups/menu-cards-groups.component';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'repair-inspection-page',
	templateUrl: 'repair-inspection.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsGroupsComponent]
})
export class RepairInspectionPage extends ScreenComponent implements OnInit {

	public app: any = App;
	
	public userPermissions: any = UserPermissions;
	
	public session: any = Session;

	public permissions = [UserPermissions.REPAIR_INSPECTIONS];

	/**
	 * List of groups and options on repair inspections menu, organized by sections.
	 */
	public groups: MenuCardsGroup[] = [
		{
			label: 'global',
			permissions: [UserPermissions.REPAIR_INSPECTIONS_CREATE, UserPermissions.REPAIR_INSPECTIONS],
			options: [
				{
					label: 'create',
					route: '/menu/repairs/inspections/edit',
					data: {createMode: true},
					icon: 'add',
					permissions: [UserPermissions.REPAIR_INSPECTIONS_CREATE],
					badge: undefined
				},
				{
					label: 'list',
					route: '/menu/repairs/inspections/list',
					data: {status: RepairInspectionStatus.ALL},
					icon: 'list',
					permissions: [UserPermissions.REPAIR_INSPECTIONS],
					badge: (async() => {
						return (await Service.fetch(ServiceList.repairInspections.countStatus, null, null, {status: RepairInspectionStatus.ALL}, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'todo',
			permissions: [UserPermissions.REPAIR_INSPECTIONS],
			options: [
				{
					label: 'todo',
					route: '/menu/repairs/inspections/list',
					data: {status: RepairInspectionStatus.TODO},
					icon: 'hammer',
					permissions: [UserPermissions.REPAIR_INSPECTIONS],
					badge: (async() => {
						return (await Service.fetch(ServiceList.repairInspections.countStatus, null, null, {status: RepairInspectionStatus.TODO}, Session.session, true)).response.count;
					})()
				},
				{
					label: 'waitingApproval',
					route: '/menu/repairs/inspections/list',
					data: {status: RepairInspectionStatus.WAITING_APPROVAL},
					icon: 'assets/icons/material/supervisor_account/outline.svg',
					permissions: [UserPermissions.REPAIR_INSPECTIONS],
					badge: (async() => {
						return (await Service.fetch(ServiceList.repairInspections.countStatus, null, null, {status: RepairInspectionStatus.WAITING_APPROVAL}, Session.session, true)).response.count;
					})()
				}
			]
		},
		{
			label: 'completed',
			permissions: [UserPermissions.REPAIR_INSPECTIONS],
			options: [
				{
					label: 'completed',
					route: '/menu/repairs/inspections/list',
					data: {status: RepairInspectionStatus.COMPLETED},
					icon: 'checkbox-outline',
					permissions: [UserPermissions.REPAIR_INSPECTIONS],
					badge: (async() => {
						return (await Service.fetch(ServiceList.repairInspections.countStatus, null, null, {status: RepairInspectionStatus.COMPLETED}, Session.session, true)).response.count;
					})()
				}
			]
		}
	];

	public ngOnInit(): void {
		super.ngOnInit();
		App.navigator.setTitle('inspections');
	}
}
