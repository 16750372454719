import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Session} from 'src/app/session';

export type AtexActionPlanCountParams = {
	// State of the action plan.
	state?: number,
	// Search value to apply on the request
	search?: string,
	// Fields to search by on the request
	searchFields?: string[],
	// Team to filter by
	filterTeam?: number
};

export type AtexActionPlanListParams = {
	// State of the action plan.
	state?: number,
	// Search value to apply on the request
	search?: string,
	// Fields to search by on the request
	searchFields?: string[],
	// Where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort direction to apply on the request
	sortDirection?: string,
	// Field to sort by
	sortField?: string,
	// Team to filter by
	filterTeam?: number
};

export type AtexActionPlanListItem = {
	// Assets associated with the action plan
	assets: {names: string, tags: string}
	// When the action plan was created
	createdAt: string,
	// Description of the action plan
	description: string,
	// Fields of the action plan
	fields: string,
	// Parent assets of the action plan
	parentAssets: {names: string, tags: string}
	// Priority of the action plan
	priority: number
	// State of the action plan
	state: number
	// When the action plan was last updated
	updatedAt: string,
	// UUID of the action plan
	uuid: string,
	// Work order the action plan is associated with
	workOrder: string,
};

export class ActionPlanService {
	/**
	 * List all action plans meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 *
	 * @returns The atex action plans that match the params.
	 */
	public static async list(params: AtexActionPlanListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<{hasMore: boolean, actionPlans: AtexActionPlanListItem[], id: number}> {
		const request = await Service.fetch(ServiceList.atex.actionPlan.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			actionPlans: request.response.actionPlans,
			id: request.id
		};
	}

	/**
	 * Count all action plans meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 *
	 * @returns The number of atex action plans that match the params.
	 */
	public static async count(params: AtexActionPlanCountParams, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		const request = await Service.fetch(ServiceList.atex.actionPlan.count, null, null, params, Session.session, hideLoading, displayError);

		return request.response.count;
	}
}
