import {AssetFluidFamily} from 'src/app/models/asset-portfolio/asset-fluid-family';
import {APAssetType} from 'src/app/models/asset-portfolio/asset-type';
import {APAssetSubType} from 'src/app/models/asset-portfolio/asset-sub-type';
import {SortDirection} from '../../../../utils/sort-direction';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {Session} from '../../../../session';
import {Atex} from '../../../../models/atex/atex';
import {
	AtexExplosionGroups,
	AtexExplosionGroupsLabels,
	AtexTemperature,
	AtexTemperatureLabels,
	AtexZones,
	AtexZonesLabel
} from '../../../../models/atex/atex-enums';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {
	InputOptionsMultipleBatchRequest,
	InputOptionsMultipleLazyPageRequest
} from '../../../../components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {AssetFluidService} from '../../services/asset-fluid.service';
import {AssetTypeListNameResponse, AssetTypeService} from '../../services/asset-type.service';
import {AssetSubTypeListNameResponse, AssetSubTypeService} from '../../services/asset-subtype.service';

export const AssetBaseLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		copy: true,
		attribute: 'tag',
		label: 'tag',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		label: 'pictures',
		attribute: 'pictures',
		type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
	},
	{
		required: false,
		label: 'documents',
		attribute: 'documents',
		type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE
	},
	{
		required: false,
		label: 'position',
		attribute: 'position',
		type: UnoFormFieldTypes.GEO_POSITION
	},
	{
		required: false,
		label: 'qr',
		attribute: 'qr',
		type: UnoFormFieldTypes.QR,
		isActive: (object, row) => { return Session.hasPermissions(UserPermissions.ASSET_PORTFOLIO_QR); }
	},
	{
		required: false,
		label: 'nfc',
		attribute: 'nfc',
		type: UnoFormFieldTypes.NFC,
		isActive: (object, row) => { return Session.hasPermissions(UserPermissions.ASSET_PORTFOLIO_NFC); }
	},
	{
		required: false,
		label: 'nameplate',
		attribute: 'nameplate',
		type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
	}
];

export const AssetStructureLayout: UnoFormField[] = [
	{
		required: true,
		attribute: 'typeUuid',
		label: 'assetType',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		showClear: true,
		multiple: false,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				sortField: '[ap_asset_type].[name]',
				sortDirection: SortDirection.ASC
			};

			try {
				const req: AssetTypeListNameResponse = await AssetTypeService.listName(data);
				request.onFinish(req.types, req.hasMore, req.id);
			} catch (e) {
				request.onError();
			}
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
			if (request.options.length > 0) {
				const types: APAssetType[] = await AssetTypeService.getBatch(request.options);
				request.onFinish(types);
			} else {
				request.onFinish([]);
			}
		},
		getOptionText: function(option: any): string {
			return option.name;
		}
	},
	{
		attribute: 'subTypeUuid',
		label: 'subtype',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		showClear: true,
		required: false,
		editable: (object) => { return object.typeUuid !== null && object.typeUuid !== undefined; },
		multiple: false,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest, params: any): Promise<void> {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				typeUuid: params.typeUuid,
				sortField: '[ap_asset_sub_type].[name]',
				sortDirection: SortDirection.ASC
			};

			try {
				const req: AssetSubTypeListNameResponse = await AssetSubTypeService.listName(data);
				request.onFinish(req.subTypes, req.hasMore, req.id);
			} catch (e) {
				request.onError();
			}
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
			if (request.options.length >= 1) {
				const subTypes: APAssetSubType[] = await AssetSubTypeService.getBatch(request.options);
				request.onFinish(subTypes);
			} else {
				request.onFinish([]);
			}
		},
		getOptionText: function(option: any): string {
			return option.name;
		}
	},
	{
		required: false,
		attribute: 'parentUuid',
		label: 'parentAsset',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		showClear: true,
		editable: (object) => { return object.typeUuid !== null && object.typeUuid !== undefined; },
		multiple: false,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: function(request: InputOptionsMultipleLazyPageRequest, params: any): void {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				typeUuid: params.typeUuid,
				sortField: '[ap_asset].[name]',
				sortDirection: SortDirection.ASC
			};

			Service.call(ServiceList.assetPortfolio.asset.listNameAllowedParents, null, null, data, Session.session, (response, xhr, id) => {
				request.onFinish(response.assets, response.hasMore, id);
			}, () => {
				request.onError();
			});
		},
		fetchOptionsBatch: function(request: InputOptionsMultipleBatchRequest): void {
			if (request.options.length > 0) {
				const data = {assets: request.options};

				Service.call(ServiceList.assetPortfolio.asset.getBatch, null, null, data, Session.session, (response) => {
					request.onFinish(response.assets);
				});
			} else {
				request.onFinish([]);
			}
		},
		getOptionText: function(option: any): string {
			if (option.tag) {
				return option.name + ' (' + option.tag + ')';
			}

			return option.name;
		}
	}
];

export const AssetModelLayout: UnoFormField[] = [
	{
		required: false,
		attribute: 'manufacturer',
		label: 'manufacturer',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'manufacturingYear',
		label: 'manufacturingYear',
		type: UnoFormFieldTypes.NUMBER
	},
	{
		required: false,
		attribute: 'model',
		label: 'model',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'serialNumber',
		label: 'serialNumber',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'installationDate',
		label: 'installationDate',
		type: UnoFormFieldTypes.DATE
	}
];

export const AssetAtexLayout: UnoFormField[] = [
	{
		required: false,
		label: 'atexZone',
		attribute: 'atex.zone',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
		sort: false,
		showClear: true,
		options: Object.values(AtexZones).map(function(value) {
			return {value: value, label: AtexZonesLabel.get(value)};
		})
	},
	{
		required: false,
		label: 'atexZoneExplosion',
		attribute: 'atex.zoneExplosion',
		type: UnoFormFieldTypes.OPTIONS,
		showClear: true,
		sort: false,
		options: Object.values(AtexExplosionGroups).map(function(value) {
			return {value: value, label: AtexExplosionGroupsLabels.get(value)};
		})
	},
	{
		required: false,
		label: 'atexZoneTemperature',
		attribute: 'atex.zoneTemperature',
		type: UnoFormFieldTypes.NUMBER,
		isActive: (object, row) => {return !Atex.isGasZone(object.atex.zone);}
	},
	{
		required: false,
		label: 'atexZoneTemperature',
		attribute: 'atex.zoneTemperature',
		type: UnoFormFieldTypes.OPTIONS,
		sort: false,
		showClear: true,
		options: Object.values(AtexTemperature).map(function(value) {
			return {value: value, label: AtexTemperatureLabels.get(value)};
		}),
		isActive: (object, row) => {return Atex.isGasZone(object.atex.zone);}
	},
	{
		required: false,
		label: 'atex',
		attribute: 'atex',
		type: UnoFormFieldTypes.ATEX_FIELD
	}
];

export const AssetFluidLayout: UnoFormField[] = [
	{
		required: false,
		label: 'fluidFamily',
		attribute: 'fluidFamilyUuid',
		type: UnoFormFieldTypes.OPTIONS,
		fetchOptions: async(object: any, row: UnoFormField) => {
			const families: AssetFluidFamily[] = (await AssetFluidService.listFamily()).families;

			row.options = families.map(function(family) {
				return {label: family.label, value: family.uuid};
			});

			row.options.push({label: 'none', value: null});
		},
		onChange: function(object: any, row: UnoFormField) {
			object.fluidType = null;
		}
	},
	{
		required: false,
		label: 'fluidType',
		attribute: 'fluidTypeUuid',
		type: UnoFormFieldTypes.OPTIONS,
		isActive: (object: any) => {return typeof object.fluidFamilyUuid === 'string';},
		fetchOptions: async(object: any, row: UnoFormField) => {
			const request = await AssetFluidService.listTypes({assetFluidFamilyUuid: object.fluidFamilyUuid});

			row.options = request.types.map(function(t) {
				return {label: t.label, value: t.uuid};
			});

			row.options.push({label: 'none', value: null});
		}
	}
];
