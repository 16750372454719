import {Component} from '@angular/core';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {App} from 'src/app/app';
import {UnoTabComponent} from '../../../../components/uno/uno-tab/uno-tab.component';
import {UnoTabSectionComponent} from '../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';

@Component({
	selector: 'demo-typography-page',
	templateUrl: 'demo-typography.page.html',
	standalone: true,
	imports: [UnoTabComponent, UnoTabSectionComponent]
})
export class DemoTypographyPage extends ScreenComponent {

	public ngOnInit(): void {
		App.navigator.setTitle('Typography');
	}
}
