import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {LDS} from 'src/app/models/pipeline-integrity/leak-detection/lds';
import {LDSAlarm} from 'src/app/models/pipeline-integrity/leak-detection/lds-alarm';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';

// LDS list API request format.
export type LDSListParams = {
	// Pipeline UUID to get the LDS from.
	pipelineUuid?: UUID,
	filters?: {
		// Search value to apply
		search?: string,
		// Fields to search by
		searchFields?: string[],
		// From where to start fetching items
		offset?: number,
		// How many items to fetch
		count?: number,
		// Sort Direction to apply on the response
		sortDirection?: string,
		// Sort Field to apply on the response
		sortField?: string,
	}
}

// Params to apply on the LDS count.
export type LDSCountParams = {
	// Pipeline UUID to get the LDS from.
	pipelineUuid?: UUID,
	filters?: {
		// Search value to apply
		search?: string,
		// Fields to search by
		searchFields?: string[],
	}
}

// LDS list API response format.
export type LDSListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched lds.
	lds: LDS[],
	// The id of the api call.
	id: number
};

// LDS last data API response format
export type LDSLastDataResponse = {
	// Channel data
	channels: {
		// Data stored as array of pair [x, y]
		data: number[][],
		// Channel index
		index: number
	}[],
	// UUID of the LDS
	ldsUuid: UUID,
	// Timestamp of the data
	timestamp: string
};

export class LDSService {
	/**
	 * List alarms for a LDS.
	 *
	 * @param uuid - LDS UUID
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns List of LDS alarms
	 */
	public static async listAlarms(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<LDSAlarm[]> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.alarm.list, null, null, {ldsUuid: uuid}, Session.session, hideLoading, displayError);
		const alarms: LDSAlarm[] = [];
		for (let j = 0; j < request.response.alarms.length; j++) {
			alarms.push(LDSAlarm.parse(request.response.alarms[j]));
		}

		return alarms;
	}

	/**
	 * List all LDS that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The list of LDS that match the params.
	 */
	public static async list(params: LDSListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<LDSListResponse> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			lds: request.response.lds.map((data: any) => {return LDS.parse(data);}),
			id: request.id
		};
	}

	/**
	 * Count all LDS that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The count of LDS that match the params.
	 */
	public static async count(params: LDSCountParams, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.count, null, null, params, Session.session, hideLoading, displayError);
		return request.response.count;
	}


	/**
	 * Get the last data available for a LDS.
	 *
	 * @param uuid - The UUID of the LDS to get the last data from.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Data of the last LDS.
	 */
	public static async lastData(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<LDSLastDataResponse> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.data.last, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return request.response;
	}

	/**
	 * Load LDS data by UUID from API
	 *
	 * @param uuid - LDS UUID
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Data of the LDS obtained from the API.
	 */
	public static async get(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<LDS> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return LDS.parse(request.response.lds);
	}
}
