import {CalendarEventAlarmType, CalendarEventAlarmTypeLabel} from '../../../../models/asset-planning/calendar-event-alarm-type';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {RepairCriticalityLevel, RepairCriticalityLevelLabel} from '../../../../models/repairs/repairs/repair-criticality';
import {RepairLongevity, RepairLongevityLabel} from '../../../../models/repairs/repairs/repair-longevity';
import {Repair} from '../../../../models/repairs/repairs/repair';
import {RepairNDT} from '../master-data/repair-ndt';
import {RepairWeldingProcedure} from '../master-data/repair-welding-procedure';
import {RepairWeldingType} from '../master-data/repair-welding-type';
import {RepairConsumable} from '../master-data/repair-consumable';
import {RepairWeldingStandard} from '../master-data/repair-welding-standard';
import {DateFrequencyPrecision} from '../../../../models/date-frequency';
import {RepairTemporaryTypeService} from '../services/repair-temporary-types.service';

export class RepairFormLayout {
	/**
	 * New repair report form layout.
	 */
	public static base: UnoFormField[] = [
		{
			label: 'uuid',
			attribute: 'uuid',
			type: UnoFormFieldTypes.UUID,
			isActive: (object: any, row: UnoFormField) => {
				return object.uuid;
			}
		},
		{
			label: 'description',
			attribute: 'description',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		},
		{
			required: true,
			attribute: 'asset',
			label: 'asset',
			type: UnoFormFieldTypes.ASSET_SELECTOR,
			multiple: false,
			isActive: true
		},
		{
			required: true,
			attribute: 'criticality',
			label: 'criticality',
			type: UnoFormFieldTypes.OPTIONS,
			sort: false,
			isEmpty: function(object: any, row: UnoFormField): boolean {
				return !object.criticality || object.criticality === RepairCriticalityLevel.NONE;
			},
			options: Object.values(RepairCriticalityLevel).map(function(value) {
				return {value: value, label: RepairCriticalityLevelLabel.get(value)};
			}),
			isActive: true
		},
		{
			label: 'qr',
			attribute: 'qr',
			type: UnoFormFieldTypes.QR,
			isActive: true
		},
		{
			attribute: 'pictures',
			label: 'pictures',
			type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE,
			isActive: true
		},
		{
			attribute: 'technicalDocuments',
			label: 'technicalDocuments',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE,
			isActive: true
		},
		{
			attribute: 'position',
			label: 'position',
			type: UnoFormFieldTypes.GEO_POSITION,
			isActive: true
		},
		{
			attribute: 'notes',
			label: 'notes',
			type: UnoFormFieldTypes.TEXT_MULTILINE,
			isActive: true
		}
	];

	/**
	 * Layout for creation of calendar events associated with the repair.
	 */
	public static calendarEvent: UnoFormField[] = [
		{
			attribute: 'createEvent',
			label: 'createEvent',
			type: UnoFormFieldTypes.CHECKBOX,
			isActive: true
		},
		{
			required: true,
			label: 'name',
			attribute: 'name',
			type: UnoFormFieldTypes.TEXT,
			isActive: (object: any, row: UnoFormField) => {
				return object.createEvent;
			}
		},
		{
			required: false,
			attribute: 'description',
			label: 'description',
			type: UnoFormFieldTypes.TEXT_MULTILINE,
			isActive: (object: any, row: UnoFormField) => {
				return object.createEvent;
			}
		},
		{
			required: false,
			attribute: 'alarmType',
			label: 'alarmType',
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
			options: Object.values(CalendarEventAlarmType).map((value) => {
				return {label: CalendarEventAlarmTypeLabel.get(value), value: value};
			}),
			isActive: (object: any, row: UnoFormField) => {
				return object.createEvent;
			}
		},
		{
			required: true,
			isActive: (object: any, row: UnoFormField) => {
				return object.alarmType.length !== 0 && object.createEvent;
			},
			attribute: 'alarmOffset',
			label: 'alarmOffset',
			dateFrequencyPrecision: DateFrequencyPrecision.HOUR,
			type: UnoFormFieldTypes.DATE_FREQUENCY
		},
		{
			required: true,
			attribute: 'date',
			label: 'date',
			isActive: (object: any, row: UnoFormField) => {
				return !object.triggerEvent && object.createEvent;
			},
			type: UnoFormFieldTypes.DATE
		},
		{
			required: true,
			attribute: 'duration',
			label: 'duration',
			isActive: (object: any, row: UnoFormField) => {
				return !object.triggerEvent && object.createEvent;
			},
			type: UnoFormFieldTypes.DATE_FREQUENCY
		},
		{
			required: true,
			attribute: 'periodic',
			label: 'periodic',
			type: UnoFormFieldTypes.CHECKBOX,
			isActive: (object: any, row: UnoFormField) => {
				return object.createEvent;
			}
		},
		{
			required: false,
			isActive: (object: any, row: UnoFormField) => {
				return object.periodic && object.createEvent;
			},
			attribute: 'periodicity',
			label: 'periodicity',
			type: UnoFormFieldTypes.DATE_FREQUENCY
		},
		{
			required: false,
			isActive: (object: any, row: UnoFormField) => {
				return object.periodic && object.createEvent;
			},
			attribute: 'periodicityRepetition',
			label: 'numberTimesRepeat',
			type: UnoFormFieldTypes.NUMBER
		},
		{
			attribute: 'team',
			label: 'team',
			type: UnoFormFieldTypes.TEAM_SELECTOR,
			isActive: (object: any, row: UnoFormField) => {
				return object.createEvent;
			}
		}
	];

	/**
	 * Repair proposal form layout.
	 */
	public static proposal: UnoFormField[] = [
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'proposal'
		},
		{
			required: true,
			attribute: 'longevity',
			label: 'longevity',
			type: UnoFormFieldTypes.OPTIONS,
			sort: false,
			options: Object.values(RepairLongevity).map(function(value) {
				return {value: value, label: RepairLongevityLabel.get(value)};
			}),
			isEmpty: function(object) {
				return !object.longevity || object.longevity === RepairLongevity.UNKNOWN;
			},
			isActive: true
		},
		{
			required: true,
			attribute: 'temporaryRepairTypeUuid',
			label: 'temporaryRepairType',
			type: UnoFormFieldTypes.OPTIONS,
			fetchOptions: async function(object: any, row: UnoFormField) {
				const options = (await RepairTemporaryTypeService.list()).types;

				row.options = [];
				for (let i = 0; i < options.length; i++) {
					row.options.push({
						label: options[i].label,
						value: options[i].uuid
					});
				}
			},
			isActive: function(object) {
				return object.longevity === RepairLongevity.TEMPORARY;
			}
		},
		{
			required: true,
			editable: true,
			isActive: true,
			attribute: 'company',
			label: 'company',
			type: UnoFormFieldTypes.COMPANY_SELECTOR,
			showClear: true
		},
		{
			required: false,
			attribute: 'cost',
			label: 'cost',
			inUnit: '€',
			unit: '€',
			type: UnoFormFieldTypes.NUMBER_UNITS,
			options: [
				{value: '€', label: '€'}
			]
		},
		{
			attribute: 'proposalDocuments',
			label: 'documents',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE,
			isActive: true
		},
		{
			attribute: 'proposalNotes',
			label: 'notes',
			type: UnoFormFieldTypes.TEXT_MULTILINE,
			isActive: true
		},
		{
			attribute: 'billOfMaterials',
			label: 'billOfMaterials',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE
		}
	];

	/**
	 * Welding job form layout.
	 */
	public static welding: UnoFormField[] = [
		{
			required: false,
			attribute: 'weldNumber',
			label: 'weldNumber',
			type: UnoFormFieldTypes.TEXT
		},
		{
			required: false,
			attribute: 'welder',
			label: 'welder',
			type: UnoFormFieldTypes.TEXT
		},
		{
			required: false,
			attribute: 'documentCode',
			label: 'documentCode',
			type: UnoFormFieldTypes.TEXT
		},
		{
			required: false,
			attribute: 'consumables',
			label: 'consumables',
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
			fetchOptions: function(object: any, row: UnoFormField) {
				RepairConsumable.load().then((options) => {
					row.options = [];
					for (let i = 0; i < options.length; i++) {
						row.options.push({
							label: options[i].label,
							value: options[i].id
						});
					}
				});
			}
		},
		{
			required: false,
			attribute: 'procedures',
			label: 'procedures',
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
			fetchOptions: function(object: any, row: UnoFormField) {
				RepairWeldingProcedure.load().then((options) => {
					row.options = [];
					for (let i = 0; i < options.length; i++) {
						row.options.push({
							label: options[i].label,
							value: options[i].id
						});
					}
				});
			}
		},
		{
			required: false,
			attribute: 'standards',
			label: 'standards',
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
			fetchOptions: function(object: any, row: UnoFormField) {
				RepairWeldingStandard.load().then((options) => {
					row.options = [];
					for (let i = 0; i < options.length; i++) {
						row.options.push({
							label: options[i].label,
							value: options[i].id
						});
					}
				});
			}
		},
		{
			required: false,
			attribute: 'types',
			label: 'weldingTypes',
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
			fetchOptions: function(object: any, row: UnoFormField) {
				RepairWeldingType.load().then((options) => {
					row.options = [];
					for (let i = 0; i < options.length; i++) {
						row.options.push({
							label: options[i].label,
							value: options[i].id
						});
					}
				});
			}
		},
		{
			required: false,
			label: 'licensed',
			attribute: 'licensed',
			type: UnoFormFieldTypes.CHECKBOX
		},
		{
			required: false,
			label: 'documents',
			attribute: 'documents',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE
		},
		{
			label: 'notes',
			attribute: 'notes',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		}
	];

	/**
	 * Welding form layout.
	 */
	public static needWelding: UnoFormField[] = [
		{
			label: 'welding',
			type: UnoFormFieldTypes.TITLE
		},
		{
			attribute: 'needsWelding',
			label: 'needsWelding',
			type: UnoFormFieldTypes.CHECKBOX
		},
		{
			required: true,
			attribute: 'weldings',
			label: 'weldings',
			type: UnoFormFieldTypes.REPETITIVE_FORM,
			fields: RepairFormLayout.welding,
			isActive: function(object: Repair): boolean {
				return object.needsWelding === true;
			},
			isEmpty: function(object) {
				return !object.weldings || object.weldings && object.weldings.length === 0;
			}
		}
	];

	/**
	 * Handle fluids form layout.
	 */
	public static handleFluids: UnoFormField[] = [
		{
			attribute: 'handleFluids',
			label: 'handleFluids',
			type: UnoFormFieldTypes.CHECKBOX
		},
		{
			attribute: 'riskAssessmentDocuments',
			label: 'riskAssessmentDocuments',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE,
			isActive: function(object: Repair): boolean {
				return object.handleFluids === true;
			}
		},
		{
			attribute: 'riskAssessmentNotes',
			label: 'riskAssessmentNotes',
			type: UnoFormFieldTypes.TEXT_MULTILINE,
			isActive: function(object: Repair): boolean {
				return object.handleFluids === true;
			}
		}
	];

	/**
	 * Non-destructive test form layout.
	 */
	public static nonDestructiveTest: UnoFormField[] = [
		{
			attribute: 'ndt',
			label: 'nonDestructiveTest',
			type: UnoFormFieldTypes.OPTIONS,
			fetchOptions: function(object: any, row: UnoFormField) {
				RepairNDT.load().then((tests) => {
					row.options = [];
					for (let i = 0; i < tests.length; i++) {
						row.options.push({
							label: tests[i].label,
							value: tests[i].id
						});
					}
				});
			}
		},
		{
			attribute: 'percentage',
			type: UnoFormFieldTypes.NUMBER,
			inUnit: '%'
		},
		{
			attribute: 'documents',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE
		},
		{
			attribute: 'notes',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		}
	];

	/**
	 * Non-destructive tests form layout.
	 */
	public static nonDestructiveTests: UnoFormField[] = [
		{
			label: 'nonDestructiveTests',
			type: UnoFormFieldTypes.TITLE
		},
		{
			required: false,
			attribute: 'nonDestructiveTests',
			label: 'tests',
			type: UnoFormFieldTypes.REPETITIVE_FORM,
			fields: RepairFormLayout.nonDestructiveTest,
			isEmpty: function(object) {
				return !object.nonDestructiveTests || object.nonDestructiveTests && object.nonDestructiveTests.length === 0;
			}
		}
	];

	/**
	 * Repair approval form layout.
	 */
	public static proposalApproval: UnoFormField[] = [
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'proposalApproval'
		},
		{
			required: true,
			attribute: 'repairOrder',
			label: 'repairOrder',
			type: UnoFormFieldTypes.TEXT,
			isActive: true
		},
		{
			attribute: 'proposalApprovalNotes',
			label: 'notes',
			type: UnoFormFieldTypes.TEXT_MULTILINE,
			isActive: true
		}
	];

	/**
	 * Repair job form layout.
	 */
	public static job: UnoFormField[] = [
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'repairJob'
		},
		{
			attribute: 'jobPictures',
			label: 'pictures',
			type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE,
			isActive: true			
		},
		{
			attribute: 'jobDocuments',
			label: 'documents',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE,
			isActive: true
		},
		{
			attribute: 'jobNotes',
			label: 'notes',
			type: UnoFormFieldTypes.TEXT_MULTILINE,
			isActive: true
		}
	];

	public static blocked: UnoFormField[] = [
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'blocked'
		},
		{
			attribute: 'blockedJustification',
			label: 'blockedJustification',
			type: UnoFormFieldTypes.TEXT_MULTILINE,
			isActive: true,
			editable: false
		},
		{
			attribute: 'blockedDocuments',
			label: 'documents',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE,
			isActive: true
		},
		{
			attribute: 'blockedNotes',
			label: 'notes',
			type: UnoFormFieldTypes.TEXT_MULTILINE,
			isActive: true
		}
	];

	/**
	 * Repair rejected message form layout.
	 */
	public static rejectionMessage: UnoFormField[] = [
		{
			label: 'repairRejected',
			type: UnoFormFieldTypes.TITLE
		},
		{
			label: 'message',
			attribute: 'rejectionMessage',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		}
	];
}
