import {Component, ViewChild, OnInit} from '@angular/core';
import {Environment} from 'src/environments/environment';
import {TranslateModule} from '@ngx-translate/core';
import {cloneDeep} from 'lodash-es';
import {UnoFormUtils} from 'src/app/components/uno-forms/uno-form/uno-form-utils';
import {UnoFormComponent} from '../../../../../components/uno-forms/uno-form/uno-form.component';
import {AssetService} from '../../../../asset-portfolio/services/asset.service';
import {Loading} from '../../../../../loading';
import {FileUtils} from '../../../../../utils/file-utils';
import {App} from '../../../../../app';
import {Locale} from '../../../../../locale/locale';
import {Modal} from '../../../../../modal';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {UnoFormModule} from '../../../../../components/uno-forms/uno-form.module';
import {RepairInspectionFormLayout} from '../repair-inspection-layout';
import {RepairInspectionStatus} from '../../../../../models/repairs/inspections/repair-inspection-status';
import {RepairInspection} from '../../../../../models/repairs/inspections/repair-inspection';
import {UUID} from '../../../../../models/uuid';
import {RepairInspectionReport} from '../../data/repair-inspection-report';
import {Repair} from '../../../../../models/repairs/repairs/repair';
import {APAsset} from '../../../../../models/asset-portfolio/asset';
import {AssetBaseLayout} from '../../../../asset-portfolio/screens/asset/asset-layout';
import {RepairFormLayout} from '../../../repair-work/screens/repairs-layout';
import {UnoTitleComponent} from '../../../../../components/uno/uno-title/uno-title.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoTabSectionComponent} from '../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../../components/uno/uno-tab/uno-tab.component';
import {RepairService} from '../../../repair-work/services/repair.service';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

@Component({
	selector: 'repair-inspection-edit-page',
	templateUrl: './repair-inspection-edit.page.html',
	standalone: true,
	imports: [UnoTabComponent, UnoTabSectionComponent, UnoFormModule, UnoButtonComponent, UnoTitleComponent, TranslateModule, PermissionsPipe]
})
export class RepairInspectionEditPage extends ScreenComponent implements OnInit {
	public assetLayout: any = AssetBaseLayout;
	
	public app: any = App;

	public layout: any = RepairInspectionFormLayout;

	public repairLayout: any = RepairFormLayout;

	public status: any = RepairInspectionStatus;

	public userPermissions: any = UserPermissions;

	public session: any = Session;

	@ViewChild('baseForm', {static: false})
	public baseForm: UnoFormComponent = null;

	@ViewChild('resultForm', {static: false})
	public resultForm: UnoFormComponent = null;

	@ViewChild('rejectedForm', {static: false})
	public rejectedForm: UnoFormComponent = null;

	public permissions = [
		UserPermissions.REPAIR_INSPECTIONS_CREATE, 
		UserPermissions.REPAIR_INSPECTIONS_EDIT
	];

	/**
	 * Repair inspection data retrieved from the API.
	 */
	public inspection: RepairInspection = null;

	/**
	 * Repair data retrieved from the API.
	 */
	public repair: Repair = null;

	/**
	 * Asset data retrieved from the API.
	 */
	public asset: APAsset = null;

	/**
	 * Flag to indicate if the page is in create mode.
	 *
	 * If true the page is used to create a new Repair.
	 */
	public createMode: boolean = false;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.inspection = null;
		this.createMode = false;

		const data = App.navigator.getData();
		if (!data || !data.createMode && !data.uuid) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		this.createMode = data.createMode === true;

		App.navigator.setTitle(this.createMode ? 'create' : 'edit');

		if (this.createMode) {
			this.inspection = new RepairInspection();
			this.inspection.date = new Date();
			if (data.repair) {
				this.inspection.repairUuid = data.repair;
			} else {
				this.layout = cloneDeep(RepairInspectionFormLayout);
				const repairField = UnoFormUtils.getFormFieldByAttribute(this.layout.base, 'repairUuid');
				repairField.editable = true;
			}
		} else {
			await this.loadData(data.uuid);
		}
	}

	/**
	 * Load the repair data from the API from UUID.
	 *
	 * @param uuid - UUID of the inspection repair.
	 */
	public async loadData(uuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.repairInspections.get, null, null, {uuid: uuid}, Session.session);
		this.inspection = RepairInspection.parse(request.response.inspection);

		// Load repair data
		if (this.inspection.repairUuid) {
			this.repair = await RepairService.get(this.inspection.repairUuid);

			// Load asset data
			if (this.repair.asset) {
				this.asset = await AssetService.get(this.repair.asset);
			}
		}
	}


	/**
	 * Export complete repair inspection report as a Docx file, using a template file.
	 *
	 * This document provides all details about the repair and its target asset, as well as the repair proposal.
	 */
	public async exportRepairInspectionDOCX(): Promise<void> {
		Loading.show();

		try {
			const report: ArrayBuffer = await RepairInspectionReport.generateReportDocx(this.inspection);

			FileUtils.writeFileArrayBuffer(this.inspection.uuid + '.docx', report);
			Modal.toast(Locale.get('reportGeneratedSuccessfully'));
		} catch (error: any) {
			if (!Environment.PRODUCTION) {
				console.error('EQS: Error generating repair inspection report.', error);
			}

			Modal.alert(Locale.get('error'), Locale.get('errorGeneratingReportDetails', {details: error}));
		}

		Loading.hide();
	}

	/**
	 * Generate a complete repair inspection report as a Docx file, using a template file, send it to the file convertion server to export as a PDF report.
	 *
	 * This document provides all details about the repair and its target asset, as well as the repair proposal.
	 */
	public async exportRepairInspectionPDF(): Promise<void> {
		Loading.show();

		try {
			const report: ArrayBuffer = await RepairInspectionReport.generateReportDocx(this.inspection);
			const form = new FormData();
			form.append('file', new Blob([report]), this.inspection.uuid + '.docx');

			const request = await Service.fetch(ServiceList.fileConverter.docxToPdf, null, null, form, null);
			FileUtils.writeFileArrayBuffer(this.inspection.uuid + '.pdf', request.response);
			Modal.toast(Locale.get('reportGeneratedSuccessfully'));
			Loading.hide();
		} catch (error: any) {
			if (!Environment.PRODUCTION) {
				console.error('EQS: Error generating repair inspection report (PDF).', error);
			}

			Modal.alert(Locale.get('error'), Locale.get('errorGeneratingReportDetails', {details: error}));
			Loading.hide();
		}
	}	

	/**
	 * Check if all the required forms (the visible ones) are filled.
	 *
	 * Automatically displays a GUI alert message on error.
	 *
	 * @returns True if all forms are filled, false otherwise.
	 */
	public allRequiredFormsFilled(): boolean {
		const allRequiredFilled: boolean = this.baseForm !== undefined && !this.baseForm.requiredFilled() ||
			this.resultForm !== undefined && !this.resultForm.requiredFilled();

		if (allRequiredFilled) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return false;
		}

		return true;
	}

	/**
	 * Update the repair inspection.
	 *
	 * By default, assumes to be info update, not a submission to the next status, unless explicitly stated.
	 *
	 * @param submit - If true updates the status of the repair inspection for the next step in the pipeline.
	 * @param stayOnPage - If true it stays on the page after update.
	 */
	public async update(submit: boolean = false, stayOnPage: boolean = false): Promise<void> {
		if (!this.allRequiredFormsFilled()) {
			return;
		}

		const inspection = structuredClone(this.inspection);

		if (this.createMode) {
			if (inspection.status === RepairInspectionStatus.NONE) {
				inspection.status = RepairInspectionStatus.TODO;
			}
		}
		
		if (submit) {
			if (inspection.status === RepairInspectionStatus.TODO) {
				inspection.status = RepairInspectionStatus.WAITING_APPROVAL;
			}
		}

		await Service.fetch(this.createMode ? ServiceList.repairInspections.create : ServiceList.repairInspections.update, null, null, inspection, Session.session);

		if (this.createMode) {
			Modal.toast(Locale.get('repairInspectionCreated'));
		} else {
			Modal.toast(Locale.get(submit ? 'repairInspectionSubmitted' : 'repairInspectionUpdated'));
		}

		if (!stayOnPage) {
			App.navigator.pop();
		}
	}

	/**
	 * Approves or rejects a submission and assumes a transition to the next/previous status respectively.
	 *
	 * @param isApproved - If true the repair is approved if false the repair is reject.
	 */
	public async approve(isApproved: boolean): Promise<void> {
		if (!this.allRequiredFormsFilled()) {
			return;
		}

		const inspection: RepairInspection = structuredClone(this.inspection);

		if (inspection.status === RepairInspectionStatus.WAITING_APPROVAL) {
			inspection.status = isApproved ? RepairInspectionStatus.COMPLETED : RepairInspectionStatus.TODO;
		}

		if (!isApproved) {
			const result = await Modal.prompt(Locale.get('confirm'), [{name: 'message', placeholder: Locale.get('messageRejection'), type: 'text'}]);

			if (result.confirm) {
				// Set rejectedMessage on repair inspection rejection
				inspection.rejectedMessage = result.data.message;

				// Repair update won't be made if no confirmation is made
				await Service.fetch(ServiceList.repairInspections.update, null, null, inspection, Session.session);
				Modal.toast(Locale.get('repairInspectionUpdated'));
				App.navigator.pop();
			}
		} else {
			// Clear rejectedMessage on repair inspection approval
			inspection.rejectedMessage = '';

			await Service.fetch(ServiceList.repairInspections.update, null, null, inspection, Session.session);
			Modal.toast(Locale.get('repairInspectionUpdated'));
			App.navigator.pop();
		}
	}

	/**
	 * Delete the repair inspection from the application.
	 *
	 * This action cannot be reversed, the user is prompted to confirm the action.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.repairInspections.delete, null, null, {uuid: this.inspection.uuid}, Session.session);
			Modal.toast(Locale.get('repairInspectionDeleted'));
			App.navigator.pop();
		}
	}
}
