import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';
import {RepairTemporaryType} from '../master-data/repair-temporary-type';

export type RepairTemporaryTypeListResponse = {
	// If there are more repairs to fetch
	hasMore: boolean,
	// Repair Temporary Types
	types: RepairTemporaryType[],
	// Request id
	id: number
}

export class RepairTemporaryTypeService {
	/**
	 * List all repair temporary types.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async list(hideLoading: boolean = false, displayError: boolean = true): Promise<RepairTemporaryTypeListResponse> {
		const request = await Service.fetch(ServiceList.repairs.temporaryType.list, null, null, {}, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			types: request.response.types.map((data: any) => {return RepairTemporaryType.parse(data);}),
			id: request.id
		};
	}

	/**
	 * Update the temporary repair types in bulk
	 *
	 * @param types - The temporary repair types to update.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async updateBulk(types: RepairTemporaryType[], hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		await Service.fetch(ServiceList.repairs.temporaryType.updateBulk, null, null, types, Session.session, hideLoading, displayError);
	}

	/**
	 * Delete a repair temporary type.
	 *
	 * @param uuid - The uuid of the temporary type to delete.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async delete(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		await Service.fetch(ServiceList.repairs.temporaryType.delete, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
	}
}
