import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Plant} from 'src/app/models/pipeline-integrity/pipeline/plant';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';

export type PlantListParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	offset?: number,
	// How many items to fetch
	count?: number,
	// Sort Direction to apply on the response
	sortDirection?: string,
	// Sort Field to apply on the response
	sortField?: string,
}

export type PlantCountParams = {
	filters: {
		// Search value to apply
		search?: string,
		// Fields to search by
		searchFields?: string[],
	}

}

// Plant list API response format.
export type PlantListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched Plants.
	plants: Plant[],
	// The id of the api call.
	id: number
};

export class PlantsService {

	/**
	 * Get plant data by its UUID from API
	 *
	 * @param plantUuid - Plant UUID
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Plant object.
	 */
	public static async get(plantUuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<Plant> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.pipeline.plant.get, null, null, {uuid: plantUuid}, Session.session, hideLoading, displayError);
		return Plant.parse(request.response.plant);
	}

	/**
	 * List all Plants that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async list(params: PlantListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<PlantListResponse> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.pipeline.plant.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			id: request.id,
			plants: request.response.plants.map((data: any) => {return Plant.parse(data);})
		};
	}

	/**
	 * Count all plants that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async count(params: PlantCountParams, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		return (await Service.fetch(ServiceList.pipelineIntegrity.pipeline.plant.count, null, null, params, Session.session, hideLoading, displayError)).response.count;
	}
}
