import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {CMP} from 'src/app/models/pipeline-integrity/mot/cmp';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';

// CMP list API request format.
export type CMPListParams = {
	// Pipeline UUID to get the CMP from.
	pipelineUuid?: UUID,
	filters?: {
		// Search value to apply
		search?: string,
		// Fields to search by
		searchFields?: string[],
		// From where to start fetching items
		offset?: number,
		// How many items to fetch
		count?: number,
		// Sort Direction to apply on the response
		sortDirection?: string,
		// Sort Field to apply on the response
		sortField?: string,
	}
}

// Params to apply on the CMP count.
export type CMPCountParams = {
	// Pipeline UUID to get the CMP from.
	pipelineUuid?: UUID,
	filters?: {
		// Search value to apply
		search?: string,
		// Fields to search by
		searchFields?: string[],
	}
}

// CMP list API response format.
export type CMPListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched CMPs.
	cmps: CMP[],
	// The id of the api call.
	id: number
};

export class CMPService {
	/**
	 * Load CMP data by UUID from API
	 *
	 * @param uuid - CMP UUID
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Data of the CMP obtained from the API.
	 */
	public static async get(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<CMP> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.cmp.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return CMP.parse(request.response.cmp);
	}

	/**
	 * List all CMPs that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async list(params: CMPListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<CMPListResponse> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.cmp.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			id: request.id,
			cmps: request.response.cmps.map((data: any) => {return CMP.parse(data);})
		};
	}

	/**
	 * Count all CMPs that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async count(params: CMPCountParams, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		return (await Service.fetch(ServiceList.pipelineIntegrity.mot.cmp.count, null, null, params, Session.session, hideLoading, displayError)).response.count;
	}
}
