import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {Component, OnInit, ViewChild} from '@angular/core';
import {cloneDeep} from 'lodash-es';
import {SortDirection} from 'src/app/utils/sort-direction';
import {Environment} from 'src/environments/environment';
import {Dl50ClientModule} from 'src/client-custom/dl50/dl50-client.module';
import {GapAnalysisService} from 'src/app/modules/gap-analysis/services/gap-analysis.service';
import {NgTemplateOutlet} from '@angular/common';
import {GAGapOrigin, GAGapOriginLabel} from 'src/app/models/gap-analysis/gaps/gap-origin';
import {DL50InspectionStatusLabel} from 'src/app/models/dl50/dl50-inspection-status';
import {CalendarEventOccurrence} from 'src/app/models/asset-planning/calendar-event-occurrence';
import {UnoFormComponent} from '../../../../../components/uno-forms/uno-form/uno-form.component';
import {InspectionWorkflowStep} from '../../../../../models/inspections/workflow/inspection-workflow-step';
import {Loading} from '../../../../../loading';
import {FileUtils} from '../../../../../utils/file-utils';
import {InspectionProjectService} from '../../../../inspections/services/inspection-project.service';
import {Inspection} from '../../../../../models/inspections/inspection/inspection';
import {InspectionProject} from '../../../../../models/inspections/project/inspection-project';
import {InspectionLayout} from '../../../../inspections/screens/inspection/inspection-layout';
import {UnoFormField} from '../../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormUtils} from '../../../../../components/uno-forms/uno-form/uno-form-utils';
import {UnoFormFieldTypes} from '../../../../../components/uno-forms/uno-form/uno-form-field-types';
import {QRGenerator} from '../../../../qr/data/qr-generator';
import {AtexInspection} from '../../../../../models/atex-inspections/inspections/atex-inspection';
import {Repair} from '../../../../../models/repairs/repairs/repair';
import {AtexInspectionResultLabel} from '../../../../../models/atex-inspections/inspections/atex-inspection-result';
import {AtexInspectionStatus, AtexInspectionStatusLabel} from '../../../../../models/atex-inspections/inspections/atex-inspection-status';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {UnoFormModule} from '../../../../../components/uno-forms/uno-form.module';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {generateUUID, UUID} from '../../../../../models/uuid';
import {APAsset} from '../../../../../models/asset-portfolio/asset';
import {AssetAtexLayout, AssetBaseLayout, AssetFluidLayout, AssetModelLayout, AssetStructureLayout} from '../asset-layout';
import {APAssetType} from '../../../../../models/asset-portfolio/asset-type';
import {APAssetFormTab} from '../../../../../models/asset-portfolio/asset-form-tab';
import {APAssetSubType} from '../../../../../models/asset-portfolio/asset-sub-type';
import {APAssetFormTabCard} from '../../../../../models/asset-portfolio/asset-form-tab-card';
import {APAssetFieldData} from '../../../../../models/asset-portfolio/asset-field-data';
import {APAssetFormBlockField} from '../../../../../models/asset-portfolio/asset-form-block-field';
import {APAssetFormBlockFieldComponentType} from '../../../../../models/asset-portfolio/asset-form-block-field-type';
import {FormSortUtils} from '../../../../../utils/form-sort-utils';
import {DigitalTwinObject} from '../../../../digital-twin/data/digital-twin-object';
import {DigitalTwinObjectLoader} from '../../../../digital-twin/data/digital-twin-object-loader';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest} from '../../../../../components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {RepairCriticalityLabelPipe} from '../../../../repairs/repair-work/pipes/repair-criticality-label.pipe';
import {RepairStatusLabelPipe} from '../../../../repairs/repair-work/pipes/repair-status-label.pipe';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoTitleComponent} from '../../../../../components/uno/uno-title/uno-title.component';
import {UnoTabSectionComponent} from '../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../../components/uno/uno-tab/uno-tab.component';
import {GAGap} from '../../../../../models/gap-analysis/gaps/gap';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {GAGapStatusLabel} from '../../../../../models/gap-analysis/gaps/gap-status';
import {UnoTableColumnLayout, UnoTableColumnType, UnoTableComponent} from '../../../../../components/uno/uno-table/uno-table.component';
import {AssetTypeService} from '../../../services/asset-type.service';
import {AssetService} from '../../../services/asset.service';
import {CalendarEvent} from '../../../../../models/asset-planning/calendar-event';
import {AssetPlanningEventService} from '../../../../asset-planning/services/asset-planning-event.service';
import {CalendarEventLayout} from '../../../../asset-planning/screens/calendar-event-layout';
import {UnoIconComponent} from '../../../../../components/uno/uno-icon/uno-icon.component';
import {UnoNoDataComponent} from '../../../../../components/uno/uno-no-data/uno-no-data.component';
import {CalendarEventSubtypesLabel, CalendarEventTypes, CalendarEventTypesLabel} from '../../../../../models/asset-planning/calendar-event-actions';
import {UnoFormModalButton} from '../../../../../components/uno-forms/uno-form-modal/uno-form-modal.component';
import {AssetReport} from '../../../data/asset-report';
import {AssetSettingsService} from '../../../services/asset-settings.service';
import {AssetSubTypeService} from '../../../services/asset-subtype.service';
import {ResourceUtils} from '../../../../../utils/resource-utils';
import {UnoResponsiveTableListComponent} from '../../../../../components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {ObjectUtils} from '../../../../../utils/object-utils';
import {RBACLayout} from '../../../../roles/screens/rbac-layout';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';


/**
 * Map of the form block field component types and their corresponding DynamicFormType to reuse already existent components.
 */
export const FormBlockFieldTypeComponents: Map<number, number> = new Map<number, number>([
	[APAssetFormBlockFieldComponentType.CHECKBOX, UnoFormFieldTypes.CHECKBOX],
	[APAssetFormBlockFieldComponentType.TEXT, UnoFormFieldTypes.TEXT],
	[APAssetFormBlockFieldComponentType.DOCUMENT_RESOURCE_MULTIPLE, UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE],
	[APAssetFormBlockFieldComponentType.IMAGE_RESOURCE_MULTIPLE, UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE],
	[APAssetFormBlockFieldComponentType.OPTIONS, UnoFormFieldTypes.OPTIONS],
	[APAssetFormBlockFieldComponentType.GEO_POSITION, UnoFormFieldTypes.GEO_POSITION],
	[APAssetFormBlockFieldComponentType.DATE, UnoFormFieldTypes.DATE],
	[APAssetFormBlockFieldComponentType.TEAM_SELECTOR, UnoFormFieldTypes.TEAM_SELECTOR],
	[APAssetFormBlockFieldComponentType.COMPANY_SELECTOR, UnoFormFieldTypes.COMPANY_SELECTOR],
	[APAssetFormBlockFieldComponentType.OPTIONS_MULTIPLE, UnoFormFieldTypes.OPTIONS_MULTIPLE],
	[APAssetFormBlockFieldComponentType.USER_SELECTOR, UnoFormFieldTypes.USER_SELECTOR],
	[APAssetFormBlockFieldComponentType.NUMBER, UnoFormFieldTypes.NUMBER],
	[APAssetFormBlockFieldComponentType.TEXT_MULTILINE, UnoFormFieldTypes.TEXT_MULTILINE],
	[APAssetFormBlockFieldComponentType.COMPANY_SELECTOR, UnoFormFieldTypes.COMPANY_SELECTOR],
	[APAssetFormBlockFieldComponentType.USER_SELECTOR, UnoFormFieldTypes.USER_SELECTOR]
]);


/**
 * Class to relate projects and inspections
 */
export class ProjectInspections {
	/**
	 * The project to relate inspections with
	 */
	public project: InspectionProject = null;

	/**
	 * Inspections to relate with project
	 */
	public inspections: Inspection[] = [];
}

export type AssetFieldTabData = {[fieldUuid: string]: any};

/**
 * Page used to edit and create assets. Should receive the UUID of the asset to edit or a createMode value set to true.
 */
@Component({
	selector: 'asset-edit-page',
	templateUrl: './asset-edit.page.html',
	styleUrls: ['./asset-edit.page.css'],
	standalone: true,
	imports: [
		UnoNoDataComponent,
		UnoIconComponent,
		UnoTabComponent,
		UnoTabSectionComponent,
		UnoTableComponent,
		UnoFormModule,
		UnoTitleComponent,
		UnoButtonComponent,
		IonicModule,
		TranslateModule,
		FormatDatePipe,
		RepairStatusLabelPipe,
		RepairCriticalityLabelPipe,
		UnoListItemComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		UnoListComponent,
		UnoResponsiveTableListComponent,
		NgTemplateOutlet,
		PermissionsPipe
	]
})

export class AssetEditPage extends ScreenComponent implements OnInit {
	@ViewChild('eventsTable') 
	public eventsTable: UnoResponsiveTableListComponent;

	public get session(): any {return Session;}

	public get environment(): any {return Environment;}

	public get dl50Module(): any {return Dl50ClientModule;}

	public get userPermissions(): any {return UserPermissions;}

	public get app(): any {return App;}

	public get baseLayout(): any {return AssetBaseLayout;}

	public get modelLayout(): any {return AssetModelLayout;}

	public rbacLayout: any = RBACLayout;

	public get atexLayout(): any {return AssetAtexLayout;}

	public get fluidLayout(): any {return AssetFluidLayout;}

	public get atexInspectionStatus(): any {return AtexInspectionStatus;}

	public get inspectionResultLabel(): any {return AtexInspectionResultLabel;}

	public get inspectionStatusLabel(): any {return AtexInspectionStatusLabel;}

	public get gapStatusLabel(): typeof GAGapStatusLabel { return GAGapStatusLabel; }

	public permissions = [UserPermissions.ASSET_PORTFOLIO];

	/**
	 * Object being edited on this screen.
	 */
	public asset: APAsset = null;

	/**
	 * Gaps list for the current asset.
	 */
	public gaps: GAGap[] = [];

	/**
	 * The number of gaps this asset currently has.
	 */
	public gapsCount: number = null;

	/**
	 * The number of events this asset currently has.
	 */
	public eventsCount: number = null;

	/**
	 * The backup of the original object being edited on this screen.
	 * 
	 * Used to check if the user has changed the object and needs to be warned about losing data.
	 */
	public assetBackup: APAsset = null;

	/**
	 * Atex inspections list for the current asset.
	 */
	public atexInspections: AtexInspection[] = [];

	/**
	 * Dynamic inspections list for the current asset, organized by project.
	 */
	public projectInspections: ProjectInspections[] = [];

	/**
	 * List of repairs associated to this asset.
	 */
	public repairs: Repair[] = [];

	/**
	 * List of digital twin objects associated to this asset.
	 */
	public digitalTwinObjects: DigitalTwinObject[] = [];

	/**
	 * Asset type object of the choosen UUID
	 */
	public assetType: APAssetType = null;

	/**
	 * Asset sub-type object of the choosen UUID
	 */
	public assetSubType: APAssetSubType = null;

	/**
	 * The array of tabs to be presented to the used with its child cards, blocks and fields.
	 */
	public tabs: APAssetFormTab[] = [];

	/**
	 * Map containing the layout, dynamically built, from asset type and sub-type tab cards for each tab uuid.
	 */
	public tabsLayouts: Map<UUID, UnoFormField[]> = new Map();

	/**
	 * The data relative to each tab by its uuid.
	 * 
	 * Tab data is built by merging all the fields from each tab fields uuids into a single object where the fields UUIDs are used as object keys and asset fields data as the values for those respective keys.
	 */
	public tabsData: Map<UUID, AssetFieldTabData> = new Map();

	/**
	 * Flag to indicate if the page is in create mode.
	 */
	public createMode: boolean = false;

	/**
	 * Asset structure layout object, needs to be adjusted based on asset and type data.
	 */
	public structureLayout: UnoFormField[];

	/**
	 * The layout to use on the asset planning Uno Table.
	 */
	public eventsTableLayout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'medium'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'medium'},
		{header: 'actionType', type: UnoTableColumnType.TEXT, attribute: 'actionType', visible: true, size: 'medium'},
		{header: 'actionSubtype', type: UnoTableColumnType.TEXT, attribute: 'actionSubtype', visible: true, size: 'medium'},
		{header: 'date', type: UnoTableColumnType.DATE, attribute: 'date', visible: true, size: 'medium'}
	];

	/**
	 * The layout to use on the gaps Uno Table.
	 */
	public gapsTableLayout: UnoTableColumnLayout[] = [
		{header: 'status', type: UnoTableColumnType.TEXT, attribute: 'status', visible: true, size: 'medium'},
		{header: 'origin', type: UnoTableColumnType.TEXT, attribute: 'origin', visible: true, size: 'medium'},
		{header: 'project', type: UnoTableColumnType.TEXT, attribute: 'inspectionProjectName', visible: true, size: 'medium'},
		{header: 'step', type: UnoTableColumnType.TEXT, attribute: 'stepName', visible: true, size: 'medium'},
		{header: 'inspection', type: UnoTableColumnType.TEXT, attribute: 'inspectionName', visible: true, size: 'medium'},
		{header: 'field', type: UnoTableColumnType.TEXT, attribute: 'inspectionFieldName', visible: true, size: 'medium'}
	];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.asset = null;
		this.gaps = [];
		this.atexInspections = null;
		this.tabs = [];
		this.repairs = [];
		this.digitalTwinObjects = [];

		const data = App.navigator.getData();
		if (!data || !data.uuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		this.createMode = data.createMode === true;

		if (this.createMode) {
			if (!Session.hasPermissions([UserPermissions.ASSET_PORTFOLIO_ASSET_CREATE])) {
				Modal.alert(Locale.get('error'), Locale.get('noPermission'));
				App.navigator.pop();
			}

			this.asset = new APAsset();
			this.asset.uuid = generateUUID();

			App.navigator.setTitle('create');

			if (data.typeUuid) {
				this.asset.typeUuid = data.typeUuid;
			}
		} else {
			this.asset = await AssetService.get(data.uuid);

			App.navigator.setTitle(this.asset.name || 'edit');
		}

		this.structureLayout = this.initializeStructureLayout();

		this.assetBackup = structuredClone(this.asset);

		if (this.asset.typeUuid) {
			this.assetType = await AssetTypeService.getType(this.asset.typeUuid);
		}

		if (this.asset.subTypeUuid) {
			this.assetSubType = await AssetSubTypeService.getSubtype(this.asset.subTypeUuid);
		}

		if (!this.createMode) {
			await Promise.all([
				this.loadGapsCount(),
				this.loadAtexInspections(),
				this.loadRepairs(),
				this.loadAssetPlanningData(),
				this.loadAssetProjectInspections(),
				this.loadAssetFieldsData(),
				this.loadDigitalTwinData(this.asset.uuid)
			]);
		}

		await this.loadTabs();
	}

	/**
	 * Check if the asset was updated.
	 */
	public assetUpdated(): boolean {
		return !ObjectUtils.equal(this.asset, this.assetBackup);
	}

	/**
	 * The loader of the gap analysis gaps table, to be listed on gap analysis tab.
	 */
	public loadGapsTableItems = async(from: number, count: number): Promise<{elements: any[]; hasMore: boolean;}> => {
		const data = {
			from: from,
			count: count,
			assetUuid: this.asset.uuid
		};

		const request = await Service.fetch(ServiceList.gapAnalysis.gap.listByAsset, null, null, data, Session.session);
		const response = request.response;

		// Transform data to a format that Uno table can access
		const elements: any[] = response.gaps.map((d: any) => {
			return {
				// Change gap status from number to its equivalent label
				uuid: d.uuid,
				status: Locale.get(GAGapStatusLabel.get(d.status)),
				origin: Locale.get(GAGapOriginLabel.get(d.origin)),
				inspectionProjectName: d.origin === GAGapOrigin.DL50_INSPECTIONS ? Locale.get('dl50') : d.inspectionProjectName,
				stepName: d.origin === GAGapOrigin.DL50_INSPECTIONS ? Locale.get(DL50InspectionStatusLabel.get(d.dl50InspectionStatus)) : d.stepName,
				inspectionName: d.inspectionName,
				inspectionFieldName: [d.inspectionField.label, d.inspectionField.text].join(' ')
			};
		});

		return {
			elements: elements,
			hasMore: response.hasMore
		};
	};


	/**
	 * Gets the total count of gaps for this asset to be used on gaps tab table.
	 */
	public async loadGapsCount(): Promise<void> {
		if (Session.hasPermissions([UserPermissions.GA_GAP])) {
			this.gapsCount = await GapAnalysisService.countGapsByAsset(this.asset.uuid);
		}
	}

	/**
	 * Navigates to the selected gap edit screen.
	 */
	public async navigateToGap(gapUuid: UUID): Promise<void> {
		if (!Session.hasPermissions([UserPermissions.GA_GAP])) {
			throw new Error('User does not have permissions to view gaps.');
		}

		if (this.assetUpdated()) {
			const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmRedirect'));
			if (!confirm) {
				return;
			}
		}

		await App.navigator.navigate('/menu/gap-analysis/gaps/edit', {uuid: gapUuid});
	}


	/**
	 * The loader of the asset planning event table, to be listed on planning tab.
	 */
	public loadCalendarEvents = async(from: number, count: number): Promise<{elements: any[]; hasMore: boolean;}> => {
		if (!Session.hasPermissions([UserPermissions.CALENDAR_EVENT])) {
			return {elements: [], hasMore: false};
		}

		const data = await AssetPlanningEventService.listEventsAndOccurrencesByAsset(this.asset.uuid, {from: from, count: count, sortDirection: SortDirection.ASC, sortField: 'date', paginationMode: 1, nextOccurrence: true});

		/**
		 * Cache of Calendar Events
		 */
		const eventCache: Map<UUID, CalendarEvent> = new Map<UUID, CalendarEvent>();

		// Transform data to a format that Uno table can access
		const elements: any[] = data.occurrences.map((occurrence: CalendarEventOccurrence) => {
			let event: CalendarEvent = eventCache.get(occurrence.calendarEventUuid);

			if (!event) {
				event = data.calendarEvents.filter((ev: CalendarEvent) => {return ev.uuid === occurrence.calendarEventUuid;})[0];
				eventCache.set(event.uuid, event);
			}

			return {
				event: event,
				name: event.name,
				description: event.description,
				actionType: Locale.get(CalendarEventTypesLabel.get(event.actionType)),
				actionSubtype: Locale.get(CalendarEventSubtypesLabel.get(event.actionSubtype)),
				date: occurrence.date
			};
		});

		return {
			elements: elements,
			hasMore: data.hasMore
		};
	};

	/**
	 * Reloads asset planning tab data.
	 */
	public async loadAssetPlanningData(): Promise<void> {
		if (Session.hasPermissions([UserPermissions.CALENDAR_EVENT])) {
			this.eventsCount = await AssetPlanningEventService.countByAsset({assetUuid: this.asset.uuid, paginationMode: 1, nextOccurrence: true});

			if (this.eventsTable) {
				await this.eventsTable.reset();
			}
		}
	}

	/**
	 * Create a new calendar event and send it to the API.
	 * 
	 * @param showModal - If false the modal to edit the event is not displayed.
	 */
	public async createEvent(showModal: boolean = true): Promise<void> {
		if (!Session.hasPermissions([UserPermissions.CALENDAR_EVENT_CREATE])) {
			throw new Error('User does not have permissions to create events.');
		}

		// Create new calendar event
		const event = new CalendarEvent();
		event.actionType = CalendarEventTypes.ASSET;
		event.assetUuid = this.asset.uuid;
		event.name = this.asset.name;
		event.date = new Date();
		event.description = this.asset.description;

		// Display the creation form
		if (showModal) {
			const layout: UnoFormField[] = cloneDeep(CalendarEventLayout);

			// Disable calendar envent type field edition
			UnoFormUtils.getFormFieldByAttribute(layout, 'actionType').editable = false;

			// Disable calendar envent asset field edition
			UnoFormUtils.getFormFieldByAttribute(layout, 'assetUuid').isActive = false;

			await Modal.form(Locale.get('create'), event, layout);
		}

		try {
			await Service.fetch(ServiceList.assetPlanning.calendarEvent.create, null, null, event, Session.session);
			Modal.toast(Locale.get('eventCreated'), 3000, 'success');
		} catch {
			Modal.toast(Locale.get('eventError'), 3000, 'danger');
		}

		await this.loadAssetPlanningData();
	}

	/**
	 * Edit a calendar event.
	 * 
	 * @param event - The calendar event to edit.
	 */
	public async editEvent(event: CalendarEvent): Promise<void> {
		if (!Session.hasPermissions([UserPermissions.CALENDAR_EVENT_EDIT])) {
			throw new Error('User does not have permissions to edit events.');
		}

		const calendarEvent: CalendarEvent = CalendarEvent.parse(structuredClone(event));

		const layout: UnoFormField[] = cloneDeep(CalendarEventLayout);

		// Disable calendar event type field edition
		UnoFormUtils.getFormFieldByAttribute(layout, 'actionType').editable = false;

		// Disable calendar event asset field edition
		UnoFormUtils.getFormFieldByAttribute(layout, 'assetUuid').isActive = false;

		// Buttons to be used on the modal.
		const buttons: UnoFormModalButton[] = [
			{
				success: true,
				label: 'ok',
				color: 'primary',
				callback: null
			},
			{
				success: false,
				label: 'cancel',
				color: 'primary',
				callback: null
			}
		];

		// Show delete button if user has permissions to do so
		if (Session.hasPermissions([UserPermissions.CALENDAR_EVENT_DELETE])) {
			buttons.push({
				success: false,
				label: 'delete',
				color: 'error',
				callback: async(): Promise<void> => {
					const confirm: boolean = await Modal.confirm(Locale.get('deleteEvent'), Locale.get('confirmDeleteEvent'));
					if (confirm) {
						await Service.fetch(ServiceList.assetPlanning.calendarEvent.delete, null, null, {uuid: event.uuid}, Session.session);
						await this.loadAssetPlanningData();
					}
				}
			});
		}

		try {
			await Modal.form(Locale.get('edit'), calendarEvent, layout, buttons);

			try {
				await Service.fetch(ServiceList.assetPlanning.calendarEvent.update, null, null, calendarEvent, Session.session);
				await this.loadAssetPlanningData();
				Modal.toast(Locale.get('eventEdited'), 3000, 'success');
			} catch {
				Modal.toast(Locale.get('eventError'), 3000, 'danger');
			}
		} catch {}
	}

	/*
	 * Generate and export a DOCX report for the asset.
	 *
	 * @param writeToFile - If true the report is written to file.
	 * @returns The report generated as array buffer.
	 */
	public async exportReportDOCX(writeToFile: boolean = true): Promise<ArrayBuffer> {
		Loading.show();

		const settings = await AssetSettingsService.get();
		const template = ResourceUtils.getURL(settings.reportTemplate, AssetReport.defaultReportURL);

		try {
			const doc: ArrayBuffer = await AssetReport.generateDocx(this.asset, template);
			if (writeToFile) {
				FileUtils.writeFileArrayBuffer(this.asset.uuid + '.docx', doc);
			}
			return doc;
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorGeneratingReportDetails', {details: e}));
		} finally {
			Loading.hide();
		}

		return null;
	}

	/**
	 * Generate a PDF report with asset data.
	 */
	public async exportReportPDF(): Promise<void> {
		Loading.show();

		const doc: ArrayBuffer = await this.exportReportDOCX(false);
		if (!doc) {
			Loading.hide();
			return;
		}

		const form = new FormData();
		form.append('file', new Blob([doc]), this.asset.uuid + '.docx');

		try {
			const convertedFile = (await Service.fetch(ServiceList.fileConverter.docxToPdf, null, null, form, null)).response;
			FileUtils.writeFileArrayBuffer(this.asset.uuid + '.pdf', convertedFile);
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorConvertingReport', {details: e}));
		}

		Loading.hide();
	}

	/**
	 * Reloads screen objects after form asset type changes
	 * 
	 * @param asset - The object used on form with the updates to reload asset type structure
	 */
	public reloadAssetTypeStructure = async(asset: any): Promise<void> => {
		// Update backup value of asset type UUID
		this.assetBackup.typeUuid = asset.typeUuid;

		// Reset subtype and parenting relations that won't fit anymore after change
		asset.subTypeUuid = null;
		asset.parentUuid = null;

		if (asset.typeUuid) {
			// Parent asset must be set if the selected asset type doesn't allow its assets to be on root (without parent).
			this.assetType = await AssetTypeService.getType(asset.typeUuid);

			// If selected asset type doesn't allow assets to be on root, it is mandatory to select a valid superior/parent asset
			const parentField: UnoFormField = UnoFormUtils.getFormFieldByAttribute(this.structureLayout, 'parentUuid');
			parentField.required = !this.assetType.allowedOnRoot;
		} else {
			this.assetType = null;
		}

		await this.loadTabs();
	};

	/**
	 * Reloads screen objects after form asset sub-type changes
	 * 
	 * @param obj - The object used on form with the updates to reload asset sub-type structure
	 */
	public reloadAssetSubTypeStructure = async(obj: any): Promise<void> => {
		// Update backup value of asset subtype UUID
		this.assetBackup.subTypeUuid = obj.subTypeUuid;

		this.assetSubType = obj.subTypeUuid ? await AssetSubTypeService.getSubtype(obj.subTypeUuid) : obj.subTypeUuid;

		await this.loadTabs();
	};

	/**
	 * Initializes the necessary callbacks/properties on the imported layouts
	 */
	public initializeStructureLayout(): UnoFormField[] {
		const layout = cloneDeep(AssetStructureLayout);

		// When asset type is changed, other properties like parent/superior asset and tabs must be reset/reloaded.
		const typeField: UnoFormField = UnoFormUtils.getFormFieldByAttribute(layout, 'typeUuid');
		typeField.onChange = async(object: any, row: UnoFormField, value: any): Promise<void> => {
			// Warn user about to loose data if proceed (when value actually changes)
			if (value !== this.assetBackup.typeUuid) {
				// Only display confirmation modal if there were already an asset type selected.
				if (this.assetBackup.typeUuid) {
					const confirm = await Modal.confirm(Locale.get('warning'), Locale.get('updateWillCauseDataLoss'));
					if (confirm) {
						await this.reloadAssetTypeStructure(object);
					} else {
						// Reset the asset type UUID with the value on backup
						object.typeUuid = this.assetBackup.typeUuid;
					}
				} else {
					await this.reloadAssetTypeStructure(object);
				}
			}
		};

		// When asset sub-type is changed, tabs must be reset/reloaded.
		const subTypeField = UnoFormUtils.getFormFieldByAttribute(layout, 'subTypeUuid');
		subTypeField.onChange = async(object: any, row: UnoFormField, value: any): Promise<void> => {
			// Warn user about data loss if proceed (when value actually changes)
			if (value !== this.assetBackup.subTypeUuid) {
				if (this.assetBackup.subTypeUuid) {
					const confirm = await Modal.confirm(Locale.get('warning'), Locale.get('updateWillCauseDataLoss'));
					if (confirm) {
						await this.reloadAssetSubTypeStructure(object);
					} else {
						// Reset the asset subtype UUID with the value on backup
						object.subTypeUuid = this.assetBackup.subTypeUuid;
					}
				} else {
					await this.reloadAssetSubTypeStructure(object);
				}
			}
		};

		return layout;
	}

	/**
	 * Loads object data available in the digital twin attached to this asset,
	 *
	 * @param assetUuid - UUID of the asset.
	 */
	public async loadDigitalTwinData(assetUuid: UUID): Promise<void> {
		if (Session.hasPermissions([UserPermissions.DIGITAL_TWIN])) {
			const request = await Service.fetch(ServiceList.digitalTwin.object.listByAsset, null, null, {assetUuid: assetUuid}, Session.session, true);
			this.digitalTwinObjects = DigitalTwinObjectLoader.parseArray(request.response.objects);
		}
	}

	/**
	 * Load inspection projects and its inspections for this asset.
	 */
	public async loadAssetProjectInspections(): Promise<void> {
		this.projectInspections = [];

		if (Session.hasPermissions([UserPermissions.INSPECTION])) {
			const projectsReq = await Service.fetch(ServiceList.inspection.project.listByAsset, null, null, {assetUuid: this.asset.uuid}, Session.session);

			// Get every project inspections filtered by asset
			for (let i = 0; i < projectsReq.response.projects.length; i++) {
				const inspections: ProjectInspections = new ProjectInspections();

				const project: InspectionProject = InspectionProject.parse(projectsReq.response.projects[i]);
				inspections.project = project;

				const inspectionsReq = await Service.fetch(ServiceList.inspection.listByAsset, null, null, {projectUuid: project.uuid, assetUuid: this.asset.uuid}, Session.session);
				inspections.inspections = inspectionsReq.response.inspections;

				this.projectInspections.push(inspections);
			}
		}
	}

	/**
	 * Callback method to prompt user with a modal to create a new inspection on a project for the current asset.
	 */
	public async createInspection(): Promise<void> {
		if (!Session.hasPermissions([UserPermissions.INSPECTION_CREATE])) {
			throw new Error('User does not have permissions to create inspections.');
		}

		// Reuse inspection layout but remove asset selector field from layout and add project selector field
		let layout: UnoFormField[] = [
			{
				required: true,
				attribute: 'projectUuid',
				label: 'project',
				type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
				multiple: false,
				showClear: true,
				identifierAttribute: 'uuid',
				fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
					const data = {
						from: request.from,
						count: request.count,
						search: request.search,
						sortField: '[inspection_project].[name]',
						sortDirection: SortDirection.ASC
					};

					const req = await InspectionProjectService.list(data);
					const options = [];
					for (let i = 0; i < req.projects.length; i++) {
						options.push({
							uuid: req.projects[i].uuid,
							name: req.projects[i].name
						});
					}

					request.onFinish(options, req.hasMore, req.id);
				},
				fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
					if (request.options.length > 0) {
						const data = {uuid: request.options[0]};

						const req = await Service.fetch(ServiceList.inspection.project.get, null, null, data, Session.session);
						request.onFinish({
							uuid: req.response.project.uuid,
							name: req.response.project.name
						});
					} else {
						request.onFinish([]);
					}
				},
				getOptionText: function(option: any): string {
					return option.name;
				}
			}
		];
		layout = layout.concat(InspectionLayout.filter(function(f: UnoFormField) { return f.attribute !== 'assetUuid';}));

		// Do not require inspection name since there's already an asset selected
		const nameField = UnoFormUtils.getFormFieldByAttribute(layout, 'name');
		nameField.required = false;

		const inspection: Inspection = new Inspection();
		// Set inspection asset
		inspection.assetUuid = this.asset.uuid;

		// Show inspection form input to the user
		await Modal.form(Locale.get('inspection'), inspection, layout);

		// Get the project default step
		const defaultStep: InspectionWorkflowStep = (await Service.fetch(ServiceList.inspection.workflowStep.list, null, null, {project: inspection.projectUuid}, Session.session)).response.steps.
			map((d: any) => { return InspectionWorkflowStep.parse(d); }).
			filter((s: InspectionWorkflowStep) => { return s.defaultStep; })[0];

		inspection.stepUuid = defaultStep.uuid;

		// Create inspection on API
		await Service.fetch(ServiceList.inspection.create, null, null, inspection, Session.session);

		// Reload all project inspections
		await this.loadAssetProjectInspections();
	}

	/**
	 * Load the list of Atex inspections available for this asset.
	 */
	 public async loadAtexInspections(): Promise<void> {
		this.atexInspections = [];

		if (Session.hasPermissions([UserPermissions.ATEX_INSPECTION])) {
			const request = await Service.fetch(ServiceList.atex.inspection.listByAsset, null, null, {uuid: this.asset.uuid}, Session.session, true);
			for (let i = 0; i < request.response.inspections.length; i++) {
				this.atexInspections.push(AtexInspection.parse(request.response.inspections[i]));
			}
		}
	}

	/**
	 * Create a new Atex inspection for this asset.
	 *
	 * User is prompted to confirm redirect to a new page to avoid loosing unsaved data on current page.
	 * 
	 * If confirmed, after the inspection is created, user is redirected to the atex inspection edit page.
	 */
	public async createAtexInspection(): Promise<void> {
		if (!Session.hasPermissions([UserPermissions.ATEX_INSPECTION_CREATE])) {
			throw new Error('User does not have permissions to create atex inspections.');
		}


		if (this.assetUpdated()) {
			const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmRedirect'));
			if (!confirm) {
				return;
			}
		}

		const request = await Service.fetch(ServiceList.atex.inspection.createForAsset, null, null, {assetUuid: this.asset.uuid}, Session.session);
		const atexsInspectionUuid: UUID = request.response.uuid;

		Modal.toast(Locale.get('createdSuccessfully'));
		App.navigator.navigate('/menu/atex/inspections/edit', {uuid: atexsInspectionUuid});

	}

	/**
	 * Load repairs associated to the asset.
	 */
	public async loadRepairs(): Promise<void> {
		this.repairs = [];

		if (Session.hasPermissions([UserPermissions.REPAIR])) {
			const request = await Service.fetch(ServiceList.repairs.listByAsset, null, null, {uuid: this.asset.uuid}, Session.session, true);
			this.repairs = request.response.repairs;
		}
	}

	/**
	 * Create a new repair for this asset.
	 *
	 * After the repair is created automatically navigates to the repair edit page.
	 */
	public async createRepair(): Promise<void> {
		if (!Session.hasPermissions([UserPermissions.REPAIR_CREATE])) {
			throw new Error('User does not have permissions to create repairs.');
		}

		if (this.assetUpdated()) {
			const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmRedirect'));
			if (!confirm) {
				return;
			}
		}

		const repair = new Repair();
		repair.asset = this.asset.uuid;

		const request = await Service.fetch(ServiceList.repairs.create, null, null, repair, Session.session);
		const repairUuid: UUID = request.response.uuid;

		Modal.toast(Locale.get('createdSuccessfully'));
		App.navigator.navigate('/menu/repairs/works/edit', {uuid: repairUuid});
	}

	/**
	 * Prepare asset fields data before sending to the API.
	 * 
	 * Iterates over all tabs and cards to get the fields data and prepare it to be sent to the API.
	 * 
	 * @returns The asset fields data.
	 */
	public prepareAssetFieldsData(): APAssetFieldData[] {
		const assetFieldsData: APAssetFieldData[] = [];
		for (const tab of this.tabs) {
			const tabData: any = this.tabsData.get(tab.uuid);
			for (const card of tab.cards) {
				if (card.block && card.block.fields) {
					for (const field of card.block.fields) {
						const fieldData: APAssetFieldData = new APAssetFieldData();
						fieldData.assetUuid = this.asset.uuid;
						fieldData.cardUuid = card.uuid;
						fieldData.fieldUuid = field.uuid;
						fieldData.data = tabData[field.uuid];
						assetFieldsData.push(fieldData);
					}
				}
			}
		}

		return assetFieldsData;
	}

	/**
	 * Update the object in the DB.
	 *
	 * @param stayOnPage - Wether to stay on page or not after update
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		if (!Session.hasPermissions(UserPermissions.ASSET_PORTFOLIO_ASSET_EDIT)) {
			throw new Error('User does not have permissions to edit assets.');
		}

		// Check required fields of asset content
		if (!UnoFormComponent.requiredFilled(this.structureLayout, this.asset) ||
			!UnoFormComponent.requiredFilled(this.baseLayout, this.asset) ||
			!UnoFormComponent.requiredFilled(this.modelLayout, this.asset) ||
			this.assetType && !UnoFormComponent.requiredFilled(this.atexLayout, this.asset)) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		// Check tabs forms required fields content
		for (const tab of this.tabs) {
			if (!UnoFormComponent.requiredFilled(this.tabsLayouts.get(tab.uuid), this.tabsData.get(tab.uuid))) {
				Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			}
		}

		await Service.fetch(this.createMode ? ServiceList.assetPortfolio.asset.create : ServiceList.assetPortfolio.asset.update, null, null, this.asset, Session.session);

		const assetFieldsData: APAssetFieldData[] = this.prepareAssetFieldsData();

		// Create/update all fields data from all tabs at once
		await Service.fetch(this.createMode ? ServiceList.assetPortfolio.asset.data.createBatch : ServiceList.assetPortfolio.asset.data.updateBatch, null, null, assetFieldsData, Session.session);

		Modal.toast(Locale.get(this.createMode ? 'createdSuccessfully' : 'updatedSuccessfully'));

		if (!stayOnPage) {
			App.navigator.pop();
			return;
		}
	}

	/**
	 * Delete the object on database.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.assetPortfolio.asset.delete, null, null, {uuid: this.asset.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}

	/**
	 * Loads all the tabs and children, needed to build the asset form layout.
	 */
	public async loadTabs(): Promise<void> {
		this.tabsLayouts = new Map<UUID, UnoFormField[]>();

		if (this.asset.typeUuid) {
			this.tabs = await AssetTypeService.listTabs(this.asset.typeUuid);
		}

		// The array that contains all the tabs' arrays of all the (sub-)sub-types
		if (this.asset.subTypeUuid) {
			// Get the tabs for the chosen (sub-)sub-type (if any)
			this.tabs = this.tabs.concat(await AssetSubTypeService.listTabs(this.asset.subTypeUuid));

			// Get the chosen sub-type
			const subType = await AssetSubTypeService.getSubtype(this.asset.subTypeUuid);

			// Get the higher levels' sub-type tabs
			if (subType.parentSubTypeUuid) {
				// Get the tabs for all the parents of this sub-sub-type (from the selected sub-sub-type, the lowest level, until reach the top parent) and add them to the array of arrays of tabs
				let subTypeParentId: UUID = subType.parentSubTypeUuid;
				do {
					// Get the sub-type parent
					const subSubType = await AssetSubTypeService.getSubtype(subTypeParentId);

					// Get tabs for this sub-type parent and add them to the beggining of the array of arrays of tabs. Since this loop will fecth from the lowest level to the highest, items are added on the beggining of the array.
					this.tabs = this.tabs.concat(await AssetSubTypeService.listTabs(subSubType.uuid));

					subTypeParentId = subSubType.parentSubTypeUuid;
				} while (subTypeParentId);
			}
		} 

		// Sort and merge cards for every tab
		for (let i = 0; i < this.tabs.length; i++) {
			const tab: APAssetFormTab = this.tabs[i];

			let cards: APAssetFormTabCard[] = await AssetTypeService.listTabCards(tab.uuid);

			// Get the (sub-...)sub-type cards for this tab
			if (this.asset.subTypeUuid) {
				cards = cards.concat(await AssetSubTypeService.listTabCards(this.asset.subTypeUuid, tab.uuid));

				// Get the chosen sub-type
				const subType = await AssetSubTypeService.getSubtype(this.asset.subTypeUuid);

				// Get the higher levels sub-type cards
				if (subType.parentSubTypeUuid) {
					// Get all the tab cards for all the parents of this sub-sub-type (from the selected sub-sub-type until reach the top parent) and add them to the array of arrays of tab cards
					let subTypeParentId: UUID = subType.parentSubTypeUuid;
					do {
						// Get the sub-sub-type
						const subSubType = await AssetSubTypeService.getSubtype(subTypeParentId);

						// Get tab cards for this sub-sub-type and add them to the beggining of the array of arrays of tab cards. Since this loop will fecth from the lowest level to the highest, items are added on the beggining of the array.
						cards = cards.concat(await AssetSubTypeService.listTabCards(subSubType.uuid, tab.uuid));

						subTypeParentId = subSubType.parentSubTypeUuid;
					} while (subTypeParentId);
				}
			}

			tab.cards = FormSortUtils.sortByIndexes(cards);

			const tabTitleField: UnoFormField = {
				label: tab.name,
				type: UnoFormFieldTypes.TITLE
			};

			// Create a single form layout per tab using all asset form fields of each tab
			let tabLayout: UnoFormField[] = [tabTitleField];
			for (let j = 0; j < tab.cards.length; j++) {
				const card: APAssetFormTabCard = tab.cards[j];

				const cardSeparatorField: UnoFormField = {
					label: card.name,
					type: UnoFormFieldTypes.TITLE
				};

				const cardLayout: UnoFormField[] = [cardSeparatorField];
				if (card.block && card.block.fields) {
					card.block.fields = FormSortUtils.sortByIndexes(card.block.fields);

					for (let k = 0; k < card.block.fields.length; k++) {
						cardLayout.push(this.newAssetDynamicFormField(card.block.fields[k]));
					}

					tabLayout = tabLayout.concat(cardLayout);
				}
			}

			this.tabsLayouts.set(tab.uuid, tabLayout);
		}

		// Sort array of tabs by their indexes
		this.tabs = FormSortUtils.sortByIndexes(this.tabs);

		await this.loadAssetFieldsData();
	}

	/**
	 * Loads all the data stored for a specific asset, in order to be presented on its tab fields.
	 */
	public async loadAssetFieldsData(): Promise<void> {
		this.tabsData = new Map<UUID, any>();

		const requestAssetFieldData = await Service.fetch(ServiceList.assetPortfolio.asset.data.list, null, null, {assetUuid: this.asset.uuid}, Session.session);
		const data = requestAssetFieldData.response.data;

		const assetFieldsData: APAssetFieldData[] = [];
		for (let i = 0; i < data.length; i++) {
			assetFieldsData.push(APAssetFieldData.parse(data[i]));
		}

		// Set every tab data object from asset fields data
		for (let j = 0; j < this.tabs.length; j++) {
			const tab: APAssetFormTab = this.tabs[j];

			this.tabsData.set(tab.uuid, APAsset.setFieldsTabDataObject(tab, assetFieldsData));
		}
	}

	/**
	 * Creates a UnoFormField from the content on a FormBlockField.
	 * 
	 * @param field - Form block field.
	 * @returns A form field object made from a form block field.
	 */
	public newAssetDynamicFormField(field: APAssetFormBlockField): UnoFormField {
		const form: UnoFormField = {
			label: field.name,
			type: FormBlockFieldTypeComponents.get(field.formFieldComponent),
			attribute: field.uuid,
			required: field.required
		};

		// Set extra data if it makes sense for the field
		if (field.formFieldComponent === APAssetFormBlockFieldComponentType.OPTIONS || field.formFieldComponent === APAssetFormBlockFieldComponentType.OPTIONS_MULTIPLE) {
			if (field.data instanceof Array) {
				form.options = field.data.map((f) => {
					return {label: f, value: f};
				});
			} else {
				form.options = [];
			}
		}

		return form;
	}

	/**
	 * Generate QR code for the asset using asset UUID.
	 */
	public generateQR(): void {
		QRGenerator.generateFile(this.asset.uuid);
	}

	/**
	 * Attach NFC code to the asset.
	 */
	public attachNFC(): void {
		App.navigator.navigate('/menu/nfc', {
			onRead: (nfcData: string) => {
				this.asset.nfc = nfcData;
			}
		});
	}
}
